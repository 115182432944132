import { Button, Input } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { YMaps, withYMaps } from '@pbe/react-yandex-maps';
import { useForm, useFormState } from 'react-final-form';

import { AppConfig } from 'config';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

const LocationSelect = () => {
  const form = useForm();
  const formState = useFormState();

  const city = formState.values.city || '';
  const region = formState.values.region === city ? '' : formState.values.region || '';
  const locationValue = `${city}${region ? `, ${region}` : ''}`;

  //=======================================
  // O2

  const [value, setValue] = useState(locationValue);
  const [isSelectVisible, setIsSelectVisible] = useState(false);

  const ListLocation = (map: any) => {
    const [mapList, setMapList] = useState([]);

    const YandexFetch = async () => {
      const location = await map.ymaps
        .suggest(value)
        .then((listLocation: any) => listLocation.map((item: any) => item.displayName));

      setMapList(location);
    };

    useEffect(() => {
      YandexFetch();
    }, [value]);

    return value !== '' ? (
      <LocationList style={{ display: isSelectVisible ? 'block' : 'none' }}>
        {mapList.map((item: string, idx: number) => (
          <li key={`location-list-${idx}`}>
            <CustomButton onClick={() => handlePlaceSelected(item)}>{item}</CustomButton>
          </li>
        ))}
      </LocationList>
    ) : null;
  };

  //=================================================

  const ConnectedLengthPrinter = useMemo(() => {
    return withYMaps(ListLocation, true, ['route']);
  }, [ListLocation]);

  // const handlePlaceSelected = (selected: AutocompleteResponse) => {
  const handlePlaceSelected = (selected: string) => {
    // const location = parseLocation(selected.address_components);

    const [city = '', region = 'Россия'] = selected.split(',');
    const newFormValues = {
      ...formState.values,
      city: city.trim(),
      region: region.trim(),
    };

    setValue(selected);
    setIsSelectVisible(false);

    form.restart(newFormValues);
  };

  const clearLocation = () => {
    form.restart({ ...formState.values, city: '', region: '' });
  };

  const inputOnChange = (inputValue: string) => {
    setValue(inputValue);
    if (inputValue !== '') {
      setIsSelectVisible(true);
    } else {
      setIsSelectVisible(false);
    }
  };

  return (
    <Root>
      <StyledInput value={value} onChange={(event) => inputOnChange(event.target.value)} placeholder={'Город'} />
      <YMaps
        query={{
          lang: 'en_RU',
          apikey: AppConfig.YANDEX_GEOCODER_API_KEY,
          suggest_apikey: AppConfig.YANDEX_GEOSUGGEST_API_KEY,
          load: 'suggest',
        }}
      >
        <ConnectedLengthPrinter />
      </YMaps>
      {/* <Autocomplete
        {...props}
        language='ru'
        apiKey={AppConfig.GOOGLE_API_KEY}
        onPlaceSelected={handlePlaceSelected}
      /> */}
      {!!locationValue && (
        <IconButton onClick={clearLocation}>
          <CloseIcon />
        </IconButton>
      )}
    </Root>
  );
};

export default LocationSelect;

const Root = styled.div`
  position: relative;
  // display: flex;
  align-items: center;
  width: 256px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);

  :hover {
    background-color: rgba(0, 0, 0, 0.13);
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  font-size: 18px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;

  &:placeholder {
    color: black;
  }

  &.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  input {
    padding: 23px 42px 6px 12px;
  }
`;

const IconButton = styled.button`
  position: absolute;
  margin-top: 14px;
  right: 8px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  z-index: 1;
`;

const CustomButton = styled(Button)`
  display: inline;
  width: 100%;
  text-align: left;
  font-size: 12px;
`;

const LocationList = styled.ul`
  position: absolute;
  top: 33px;
  height: 75px;
  overflow-y: scroll;
  background-color: #f5f5f5;
  border: 1px solid #d0d0d0;
  z-index: 10;
  padding: 0;

  li {
    list-style: none;
  }
`;
