import { ImageInput as DefaultImageInput, ImageInputProps as DefaultImageInputProps, ImageField } from 'react-admin';
import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { api } from 'api';
import styled from 'styled-components';
import { convertToBase64 } from 'utils';

const getUrl = (uri: string) => uri.split('?')[0];

const ImageInput = ({ source, ...props }: ImageInputProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm();
  const formState = useFormState();

  const photoUrl = formState.values[source];

  const handleChange = async (file: File | null) => {
    if (file) {
      try {
        setIsLoading(true);

        const base64 = await convertToBase64(file);

        const response = await api.Promotions.promotionsCrudControllerCreateSignedUrlForPhoto({ filename: base64 });
        const photoUrl = response.data.url;

        form.restart({ ...formState.values, [source]: photoUrl });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <DefaultImageInput onChange={handleChange} {...props} source="image-input">
        <HiddenImageField source="src" title="title" />
      </DefaultImageInput>
      {isLoading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        !!photoUrl && <Image src={photoUrl} />
      )}
    </>
  );
};

export default ImageInput;

type ImageInputProps = DefaultImageInputProps;

const Image = styled.img`
  position: relative;
  width: 150px;
  height: 150px;
  top: -20px;
  object-fit: cover;
  overflow: hidden;
`;

const LoaderContainer = styled.div`
  display: flex;
  width: 150px;
  height: 150px;
  top: -20px;
  justify-content: center;
  align-items: center;
`;

const HiddenImageField = styled(ImageField)`
  width: 0;
  height: 0;
  opacity: 0;
  * {
    width: 0;
    height: 0;
  }
`;
