import { DateField, ImageField, ResourceComponentProps, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import React, { FC } from 'react';

import styled from 'styled-components';

const PromotionShow: FC<ResourceComponentProps> = (props) => {
  return (
    <Show title="Рекламное объявление" {...props}>
      <TabbedShowLayout>
        <Tab label="Об объявлении">
          <StyledImageField label="Изображение" source="photoUrl" />
          <TextField source="id" />
          <TextField label="Заголовок" source="title" />
          <TextField label="Описание" source="description" />
          <TextField label="Ссылка" source="link" />
          <TextField label={'Информация о рекламодателе'} source={'about_promoter'} />
          <TextField label="Кол-во переходов по баннеру" source="numberOfClicks" />
          <TextField label="Кол-во просмотров баннера" source="numberOfViews" />
          <TextField label="Город" source="city" />
          <TextField label="Область" source="region" />
          <DateField locales="rus" label="Создано" source="createdAt" />
          <DateField locales="rus" label="Обновлено" source="updatedAt" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default PromotionShow;

const StyledImageField = styled(ImageField)`
  img {
    width: 150px;
    height: 150px;
  }
`;
