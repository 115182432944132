import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  ResourceComponentProps,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  Button,
  ExportButton,
  useRefresh,
} from 'react-admin';
import React, { FC } from 'react';
import styled from 'styled-components';
import { api } from 'api';

const OffersList = (props: ResourceComponentProps) => {
  const refresh = useRefresh();

  const extendOffers = async () => {
    try {
      await api.V1AdminPanelOffersApi.offersCrudControllerExtendForMounth();
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const OffersFilter: FC<ResourceComponentProps> = (props) => (
    <Filter {...props}>
      <TextInput label="Поиск по названию" source="title||$contL" alwaysOn />
      {props.permissions === 'super_admin' && <TextInput label="Поиск по городу" source="city||$contL" alwaysOn />}
    </Filter>
  );

  const ListActions = () => (
    <TopToolbar>
      <Button onClick={extendOffers} label="Продлить все объявления" />
      <ExportButton />
    </TopToolbar>
  );

  return (
    <List filters={<OffersFilter />} {...props} actions={<ListActions />}>
      <Datagrid>
        <StyledImageField sortable={false} label="Изображение" source="photoUrls.[0]" />
        <TextField label="Заголовок" source="title" />
        <TextField label="Город" source="city" />
        <TextField label="Автор" source="customer.fullName" />
        <TextField label="Тип" source="type" />
        <TextField label="Категория" source="offerWithCategories.[0].category.title" />
        <DateField locales="rus" label="Создано" source="createdAt" />
        <DateField locales="rus" label="Обновлено" source="updatedAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default OffersList;

const StyledImageField = styled(ImageField)`
  width: 50px;
  height: 50px;
  img {
    width: 50px;
    height: 50px;
    margin: 0;
  }
`;
