import {
  DateField,
  ImageField,
  Record,
  ResourceComponentProps,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import React, { FC } from 'react';

import { ResourceName } from 'types';
import styled from 'styled-components';

const CustomerShowButton = ({ record }: { record?: Record }) => (
  <ShowButton basePath={`/${ResourceName.CUSTOMERS}`} label="Подробнее" record={{ id: record?.customerId }} />
);

const BlockedCustomerShowButton = ({ record }: { record?: Record }) => (
  <ShowButton basePath={`/${ResourceName.CUSTOMERS}`} label="Подробнее" record={{ id: record?.blockedCustomerId }} />
);

const BlockCustomersShow: FC<ResourceComponentProps> = (props) => {
  return (
    <Show title="Бан" {...props}>
      <TabbedShowLayout>
        <Tab label="О бане">
          <TextField source="id" />
          <TextField label="id пользователя" source="customerId" />
          <TextField label="id забаненного" source="blockedCustomerId" />
          <DateField locales="rus" label="Создано" source="createdAt" />
        </Tab>
        <Tab label="О пользователе">
          <StyledImageField label="Аватар" source="customer.avatar" />
          <TextField label="id" source="customerId" />
          <TextField label="Имя" source="customer.fullName" />
          <TextField label="Город" source="customer.city" />
          <DateField locales="rus" label="Зарегистрирован" source="customer.createdAt" />
          <CustomerShowButton />
        </Tab>
        <Tab label="О забаненом">
          <StyledImageField label="Аватар" source="blockedCustomer.avatar" />
          <TextField label="id" source="blockedCustomerId" />
          <TextField label="Имя" source="blockedCustomer.fullName" />
          <TextField label="Город" source="blockedCustomer.city" />
          <DateField locales="rus" label="Зарегистрирован" source="blockedCustomer.createdAt" />
          <BlockedCustomerShowButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default BlockCustomersShow;

const StyledImageField = styled(ImageField)`
  img {
    width: 150px;
    height: 150px;
    border-radius: 75px;
  }
`;
