import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  FilterList,
  FilterListItem,
  ImageField,
  List,
  ResourceComponentProps,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin';
import { CardContent, Card as MuiCard, withStyles } from '@material-ui/core';
import React, { FC } from 'react';

import FilterListIcon from '@material-ui/icons/FilterList';
import styled from 'styled-components';

export const PromotionsFilter: FC<ResourceComponentProps> = (props) => (
  <Filter {...props}>
    <StyledTextInput label="Поиск по названию" source="title||$contL" alwaysOn />
  </Filter>
);

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '250px',
      marginRight: '20px',
      borderRadius: 20,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const LastActiveFilter = (props: ResourceComponentProps) => (
  <Card>
    <CardContent>
      <PromotionsFilter />
      <FilterList label="Фильтр" icon={<FilterListIcon />} {...props}>
        <FilterListItem
          label="Активные"
          value={{
            active: true,
          }}
        />
        <FilterListItem
          label="Не активные"
          value={{
            active: false,
          }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

const ListActions = (props: ResourceComponentProps) => {
  return (
    <TopToolbar>
      {props?.permissions === 'super_admin' && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};

const PromotionsList = (props: ResourceComponentProps) => {
  return (
    <List
      aside={<LastActiveFilter />}
      className="rrrrr"
      actions={<ListActions permissions={props?.permissions} />}
      empty={false}
      {...props}
    >
      <Datagrid>
        <StyledImageField label="Изображение" sortable={false} source="photoUrl" />
        <TextField label="Заголовок" source="title" />
        <TextField label="Описание" source="description" />
        <BooleanField label="Статус" source="active" color="primary" />
        <BooleanField label="По всей России" source="aroundRussia" color="primary" />
        <TextField label="Порядок вывода" source="position" />
        <TextField label="Ссылка" source="link" />
        <TextField label="Город" source="city" />
        <TextField label="Область" source="region" />
        <TextField label="Кол-во переходов по баннеру" source="numberOfClicks" />
        <TextField label="Кол-во просмотров баннера" source="numberOfViews" />
        <DateField label="Создано" locales="rus" source="createdAt" />
        <DateField label="Изменено" locales="rus" source="updatedAt" />
        <DateField label="дата окончания" locales="rus" source="completionDate" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default PromotionsList;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;
const StyledImageField = styled(ImageField)`
  width: 50px;
  height: 50px;
  img {
    width: 50px;
    height: 50px;
    margin: 0;
  }
`;
