import { FormControlLabel, Switch } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { api } from 'api';

const Settings = () => {
  const [isModerate, setIsModerate] = useState(false);

  useEffect(() => {
    getAdminSettings();
  }, []);

  const getAdminSettings = async () => {
    const response = await api.V1AdminPanelAdminPanelSettingsApi.adminPanelSettingsControllerGetSettings();

    setIsModerate(response.data.switchModeration);
  };

  const switchModeration = async () => {
    const response = await api.V1AdminPanelAdminPanelSettingsApi.adminPanelSettingsControllerSwitchSettings({
      switchModeration: !isModerate,
    });

    setIsModerate(response.data.switchModeration);
  };

  return (
    <Card>
      <Title title="Настройки" />
      <CardContent>
        <FormControlLabel control={<Switch checked={isModerate} onChange={switchModeration} />} label="Модерация" />
      </CardContent>
    </Card>
  );
};

export default Settings;
