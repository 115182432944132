import { AutocompleteInput, Create, ResourceComponentProps, SimpleForm, TextInput } from 'react-admin';
import React, { FC } from 'react';

export const OfferErrorCreate: FC<ResourceComponentProps> = (props) => (
  <Create title="Создание причины отклонения" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Название" source="title" />
      <TextInput label="Сообщение" source="message" />
    </SimpleForm>
  </Create>
);

export default OfferErrorCreate;
