import { AppConfig } from 'config';
import crudProvider from 'ra-data-nestjsx-crud';
import { fetchUtils } from 'react-admin';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  if (url.includes('moderating-offers')) {
    url = url.replace('moderating-offers', 'offers');
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = crudProvider(AppConfig.API_URL, httpClient);
