import { MenuItemLink, Menu as MenuLayout, MenuProps, ReduxState, getResources } from 'react-admin';

import { FC } from 'react';
import { ResourceName } from 'types';
import SettingsIcon from '@material-ui/icons/Settings';
import { createElement } from 'react';
import { useSelector } from 'react-redux';

const Menu: FC<MenuProps> = ({ onMenuClick }) => {
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <MenuLayout>
      {resources
        .filter((resource) => !!resource.icon)
        .map((resource) => {
          if (resource.name === ResourceName.ME) {
            return (
              <MenuItemLink
                key={'me'}
                to={'/me'}
                primaryText={'Настройки профиля'}
                leftIcon={createElement(SettingsIcon)}
                onClick={onMenuClick}
                sidebarIsOpen={open}
              />
            );
          }

          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={(resource.options && resource.options.label) || resource.name}
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          );
        })}
      <MenuItemLink
        key={'settings'}
        to={`/settings`}
        primaryText={'Настройки модерации'}
        leftIcon={createElement(SettingsIcon)}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    </MenuLayout>
  );
};

export default Menu;
