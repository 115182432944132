import {
  Button,
  Datagrid,
  Filter,
  List,
  Record,
  ResourceComponentProps,
  ShowButton,
  TextField,
  TextInput,
  useRefresh,
} from 'react-admin';

import { AppConfig } from 'config';
import BlockIcon from '@material-ui/icons/Block';
import { Customer } from 'api/generated';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  button: { color: 'red' },
});

export const CustomersFilter: FC<ResourceComponentProps> = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск по имени" source="firstName||$contL" alwaysOn />
    <TextInput label="Поиск по городу" source="city||$contL" alwaysOn />
    <TextInput label="Поиск по id" source="id||$eq" alwaysOn />
  </Filter>
);

const BanButton = (props: { record?: Record }) => {
  const styles = useStyles();
  const { id } = props.record as Customer;
  const refresh = useRefresh();

  const handleBlockCustomer = async () => {
    try {
      await fetch(`${AppConfig.BASE_API_URL}/v1/admin/managers/${id}`, {
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  return <Button className={styles.button} onClick={handleBlockCustomer} label={'Удалить'} startIcon={<BlockIcon />} />;
};

const ManagersList: FC<ResourceComponentProps> = (props: ResourceComponentProps) => {
  return (
    <List bulkActionButtons={false} filters={<CustomersFilter />} {...props}>
      <Datagrid>
        <TextField label="Имя" source="firstName" />
        <TextField label="Фамилия" source="lastName" />
        <TextField label="Роль" source="role" />
        <TextField label="Электронная почта" source="email" />
        <TextField label="Почта для обратной связи" source="feedbackEmail" />
        <TextField label="Город" source="city" />
        <TextField label="Регион" source="region" />
        <ShowButton />
        <BanButton />
      </Datagrid>
    </List>
  );
};

export default ManagersList;
