import {
  ArrayField,
  DateField,
  ImageField,
  ResourceComponentProps,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import React, { FC } from 'react';

import { StringToLabelObject } from 'components';

const OfferShow: FC<ResourceComponentProps> = (props) => {
  return (
    <Show title="Объявление" {...props}>
      <TabbedShowLayout>
        <Tab label="Об объявлении">
          <TextField source="id" />
          <ArrayField label="Изображения" source="photoUrls">
            <SingleFieldList>
              <StringToLabelObject>
                <ImageField source="label" />
              </StringToLabelObject>
            </SingleFieldList>
          </ArrayField>
          <TextField label="Заголовок" source="title" />
          <TextField label="Описание" source="description" />
          <TextField label="Город" source="city" />
          <TextField label="Тип" source="type" />
          <TextField label="Категория" source="offerWithCategories.[0].category.title" />
          <TextField label="Статус" source="status" />
          <DateField locales="rus" label="Создано" source="createdAt" />
          <DateField locales="rus" label="Обновлено" source="updatedAt" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OfferShow;
