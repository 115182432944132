import Card from '@material-ui/core/Card';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

type OfferErrorCardProps = {
  id: string;
  title: string;
  message: string;
  remove?: () => void;
};

const OfferErrorCard: React.FC<OfferErrorCardProps> = ({ id, title, message, remove }) => {
  return (
    <Root>
      <Header>
        <Title>{title}</Title>
        <Delete onClick={remove} />
      </Header>
      <Message>{message}</Message>
    </Root>
  );
};

const Root = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  min-width: 10rem;
  max-width: 25rem;
  border-radius: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Title = styled.h4`
  width: 100%;
  overflow-wrap: break-word;
`;

const Delete = styled(CloseIcon)`
  cursor: pointer;
  position: absolute;
  left: 95%;
  top: -5px;
`;

const Message = styled.p`
  overflow-wrap: break-word;
`;

export default OfferErrorCard;
