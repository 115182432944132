import {
  Datagrid,
  DateField,
  ImageField,
  Pagination,
  ReferenceManyField,
  ResourceComponentProps,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import React, { FC } from 'react';

import { ResourceName } from 'types';
import styled from 'styled-components';

const CustomerShow: FC<ResourceComponentProps> = (props) => {
  return (
    <Show title="Пользователь" {...props}>
      <TabbedShowLayout>
        <Tab label="О пользователе">
          <StyledImageField label="Аватар" source="avatar" />
          <TextField source="id" />
          <TextField label="Имя" source="fullName" />
          <TextField label="Телефон" source="phone" />
          <TextField label="Область" source="region" />
          <TextField label="Город" source="city" />
          <TextField label="Район" source="district" />
          <TextField label="Адрес" source="address" />
          <DateField locales="rus" label="Зарегистрирован" source="createdAt" />
        </Tab>
        <Tab label="Диалоги">
          <ReferenceManyField
            label=""
            pagination={<Pagination />}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            reference={ResourceName.CHATS}
            target="chatMembers.customerId"
          >
            <Datagrid>
              <TextField label="Объявление" source="title" />
              <TextField sortable={false} label="Пользователь 1" source="chatMembers.[0].customer.fullName" />
              <TextField sortable={false} label="Пользователь 2" source="chatMembers.[1].customer.fullName" />
              <DateField locales="rus" label="Создано" source="createdAt" />
              <DateField locales="rus" label="Обновлено" source="updatedAt" />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CustomerShow;

const StyledImageField = styled(ImageField)`
  img {
    width: 150px;
    height: 150px;
    border-radius: 75px;
  }
`;
