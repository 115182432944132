import { AutocompleteInput, Create, ResourceComponentProps, SimpleForm, TextInput } from 'react-admin';
import React, { FC } from 'react';

export const CategoryCreate: FC<ResourceComponentProps> = (props) => (
  <Create title="Создание категории" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Название" source="title" />
      <AutocompleteInput
        label="Тип"
        source="type"
        choices={[
          { id: 'DISH', name: 'Готовая еда' },
          { id: 'INGREDIENT', name: 'Продукт' },
        ]}
      />
    </SimpleForm>
  </Create>
);

export default CategoryCreate;
