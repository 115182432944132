import {
  V1AdminPanelAdminFeaturesApi,
  V1AdminPanelAdminPanelSettingsApi,
  V1AdminPanelPromotionsApi,
  V1AdminsApi,
  V1ManagerProfileApi,
  V1ManagerProfileApiFactory,
  V1ManagerProfileApiFp,
  V1AdminPanelOffersApi,
} from './generated';

import { AppConfig } from 'config';
import { http } from '../services';

export const api = {
  V1AdminsApi: new V1AdminsApi(undefined, AppConfig.BASE_API_URL, http),
  Managers: new V1ManagerProfileApi(undefined, AppConfig.BASE_API_URL, http),
  Promotions: new V1AdminPanelPromotionsApi(undefined, AppConfig.BASE_API_URL, http),
  V1AdminPanelAdminFeaturesApi: new V1AdminPanelAdminFeaturesApi(undefined, AppConfig.BASE_API_URL, http),
  V1AdminPanelAdminPanelSettingsApi: new V1AdminPanelAdminPanelSettingsApi(undefined, AppConfig.BASE_API_URL, http),
  V1AdminPanelOffersApi: new V1AdminPanelOffersApi(undefined, AppConfig.BASE_API_URL, http),
};
