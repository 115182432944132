import { Button, ReferenceArrayInput, SimpleForm, useRedirect } from 'react-admin';
import { ErrorSelectInput, OfferErrorCard } from 'components';
import { Field, Form } from 'react-final-form';
import { Input, TextField as MUITextField } from '@material-ui/core';
import React, { useState } from 'react';

import { OfferError } from 'types';
import { OfferStatusEnum } from 'api/generated';
import { http } from 'services';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

type OfferRejectTabProps = {
  offerId?: string;
};

type FormValues = { errorTitle: string; errorMessage: string };

const OfferRejectTab: React.FC<OfferRejectTabProps> = ({ offerId }) => {
  const redirect = useRedirect();

  const [offerErrors, setOfferErrors] = useState<OfferError[]>([]);

  const handleSelect = (errorData: OfferError) => {
    if (!offerErrors.find((error) => error.id === errorData.id)) {
      setOfferErrors((prev) => [...prev, errorData]);
    }
  };

  const addCustomOfferError = (values: FormValues) => {
    const newError = {
      id: uuidv4(),
      title: values.errorTitle,
      message: values.errorMessage,
    };
    setOfferErrors((prev) => [...prev, newError]);
  };

  const deleteOfferError = (id: string) => {
    setOfferErrors((prev) => prev.filter((item) => item.id !== id));
  };

  const reject = async () => {
    const errors = offerErrors.map((error) => {
      return { errorTitle: error.title, errorMessage: error.message };
    });
    const body = {
      errors,
      status: OfferStatusEnum.Rejected,
    };
    await http.patch(`/v1/admin/offers/process/${offerId}`, body);
    redirect('/moderating-offers');
  };

  return (
    <>
      <UpperWrapper>
        <FormRow>
          <TabTitle>Добавить готовую причину</TabTitle>
          <StyledSimpleForm toolbar={false}>
            <ReferenceArrayInput
              source="Причины отклонения"
              reference="offer-errors"
              sort={{ field: 'title', order: 'ASC' }}
            >
              <ErrorSelectInput onSelect={handleSelect} optionText="title" />
            </ReferenceArrayInput>
          </StyledSimpleForm>
        </FormRow>
        <FormRow>
          <TabTitle>Добавить свою причину</TabTitle>
          <Form
            onSubmit={addCustomOfferError}
            render={({ handleSubmit, form }) => (
              <AddForm
                onSubmit={(event) => {
                  handleSubmit(event);
                  form.reset();
                }}
              >
                <Field name="errorTitle">
                  {(props) => (
                    <Input
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      placeholder="Название причины"
                      inputProps={{ maxLength: 70 }}
                      required
                    />
                  )}
                </Field>
                <Field name="errorMessage">
                  {(props) => (
                    <MUITextField
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      multiline
                      placeholder="Сообщение причины"
                      inputProps={{ maxLength: 200 }}
                      required
                    />
                  )}
                </Field>
                <Button color="default" size="small" type="submit">
                  <p>Добавить</p>
                </Button>
              </AddForm>
            )}
          ></Form>
        </FormRow>
      </UpperWrapper>
      <OfferCardList>
        {offerErrors?.map((offerError) => (
          <OfferErrorCard
            key={offerError.id}
            id={offerError.id}
            title={offerError.title}
            message={offerError.message}
            remove={() => deleteOfferError(offerError.id)}
          />
        ))}
      </OfferCardList>
      <ButtonWrapper>
        <Button color="primary" onClick={reject}>
          <p>Отклонить</p>
        </Button>
      </ButtonWrapper>
    </>
  );
};
const UpperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5rem;
  padding: 2rem 4rem;
`;

const TabTitle = styled.h3``;

const StyledSimpleForm = styled(SimpleForm)`
  display: flex;
  justify-content: flex-start;
  & > div,
  div:last-child {
    padding: 0;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 20rem;
`;

const OfferCardList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
`;
const ButtonWrapper = styled.div`
  margin-left: 2rem;
  margin-bottom: 0.5rem;
`;

export default OfferRejectTab;
