import { AutocompleteInput, Edit, ResourceComponentProps, SimpleForm, TextInput } from 'react-admin';
import React, { FC } from 'react';

const CategoryEdit: FC<ResourceComponentProps> = (props) => (
  <Edit title="Изменение категории" {...props}>
    <SimpleForm>
      <TextInput label="Название" source="title" />
      <AutocompleteInput
        label="Тип"
        source="type"
        choices={[
          { id: 'DISH', name: 'Готовая еда' },
          { id: 'INGREDIENT', name: 'Продукт' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default CategoryEdit;
