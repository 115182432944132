import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';

import { api } from 'api';

export default async (type: string, params: { username: string; password: string; status: number }) => {
  if (type === AUTH_LOGIN) {
    const response = await api.V1AdminsApi.adminsControllerSignIn({
      email: params.username,
      password: params.password,
    });
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    const { token, user } = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('role', user.role);
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    if (params.status === 401) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  }

  return Promise.resolve();
};
