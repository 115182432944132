import {
  DateField,
  ImageField,
  ResourceComponentProps,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  Title,
} from 'react-admin';
import React, { FC, useEffect, useState } from 'react';

import { api } from 'api';
import styled from 'styled-components';

const ManagersShow: FC<ResourceComponentProps> = (props) => {
  return (
    <Show title="Рекламное объявление" {...props}>
      <TabbedShowLayout>
        <Tab label="Об менеджере">
          <TextField label="Имя" source="firstName" />
          <TextField label="Фамилия" source="lastName" />
          <TextField label="Роль" source="role" />
          <TextField label="Электронная почта" source="email" />
          <TextField label="Почта для обратной связи" source="feedbackEmail" />
          <TextField label="Город" source="city" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ManagersShow;

const StyledImageField = styled(ImageField)`
  img {
    width: 150px;
    height: 150px;
  }
`;
