/* tslint:disable */
/* eslint-disable */
/**
 * Cocktail API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    role: AdminRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    feedbackEmail: string;
    /**
     * 
     * @type {boolean}
     * @memberof Admin
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Admin
     */
    aroundRussia: boolean;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminRoleEnum {
    Admin = 'admin',
    SuperAdmin = 'super_admin'
}

/**
 * 
 * @export
 * @interface AdminCreateDto
 */
export interface AdminCreateDto {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDto
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDto
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateDto
     */
    feedbackEmail: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminCreateDto
     */
    aroundRussia: boolean;
}
/**
 * 
 * @export
 * @interface AdminDto
 */
export interface AdminDto {
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    role: AdminDtoRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    feedbackEmail: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDto
     */
    aroundRussia: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminDtoRoleEnum {
    Admin = 'admin',
    SuperAdmin = 'super_admin'
}

/**
 * 
 * @export
 * @interface AdminPanelSetting
 */
export interface AdminPanelSetting {
    /**
     * 
     * @type {string}
     * @memberof AdminPanelSetting
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminPanelSetting
     */
    switchModeration: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminPanelSetting
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPanelSetting
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPanelSetting
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface AdminUpdateDto
 */
export interface AdminUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDto
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDto
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateDto
     */
    feedbackEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateDto
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUpdateDto
     */
    aroundRussia?: boolean;
}
/**
 * 
 * @export
 * @interface AdminsSignInBodyDto
 */
export interface AdminsSignInBodyDto {
    /**
     * 
     * @type {string}
     * @memberof AdminsSignInBodyDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AdminsSignInBodyDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface AppleSignInDto
 */
export interface AppleSignInDto {
    /**
     * 
     * @type {string}
     * @memberof AppleSignInDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AppleSignInDto
     */
    nonce: string;
    /**
     * 
     * @type {string}
     * @memberof AppleSignInDto
     */
    fullName: string;
}
/**
 * 
 * @export
 * @interface AuthAdminDto
 */
export interface AuthAdminDto {
    /**
     * 
     * @type {AdminDto}
     * @memberof AuthAdminDto
     */
    user: AdminDto;
    /**
     * 
     * @type {string}
     * @memberof AuthAdminDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface AuthCustomerDto
 */
export interface AuthCustomerDto {
    /**
     * 
     * @type {CustomerDto}
     * @memberof AuthCustomerDto
     */
    user: CustomerDto;
    /**
     * 
     * @type {string}
     * @memberof AuthCustomerDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface BlockCustomer
 */
export interface BlockCustomer {
    /**
     * 
     * @type {string}
     * @memberof BlockCustomer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BlockCustomer
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof BlockCustomer
     */
    blockedCustomerId: string;
    /**
     * 
     * @type {Customer}
     * @memberof BlockCustomer
     */
    customer: Customer;
    /**
     * 
     * @type {Customer}
     * @memberof BlockCustomer
     */
    blockedCustomer: Customer;
    /**
     * 
     * @type {string}
     * @memberof BlockCustomer
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BlockCustomer
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof BlockCustomer
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface BlockCustomersPaginatedDto
 */
export interface BlockCustomersPaginatedDto {
    /**
     * 
     * @type {Array<BlockCustomer>}
     * @memberof BlockCustomersPaginatedDto
     */
    items: Array<BlockCustomer>;
    /**
     * 
     * @type {Cursor}
     * @memberof BlockCustomersPaginatedDto
     */
    cursor: Cursor;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    type: CategoryTypeEnum;
    /**
     * 
     * @type {Array<OfferWithCategory>}
     * @memberof Category
     */
    offerWithCategories?: Array<OfferWithCategory>;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryTypeEnum {
    Dish = 'DISH',
    Ingredient = 'INGREDIENT'
}

/**
 * 
 * @export
 * @interface ChangePhoneDto
 */
export interface ChangePhoneDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePhoneDto
     */
    newPhone: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePhoneDto
     */
    smscode: string;
}
/**
 * 
 * @export
 * @interface Chat
 */
export interface Chat {
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    lastMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    lastSenderName?: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    lastSenderId?: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    chatAvatar?: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    offerId: string;
    /**
     * 
     * @type {number}
     * @memberof Chat
     */
    unreadMessagesCount?: number;
    /**
     * 
     * @type {Array<Message>}
     * @memberof Chat
     */
    messages: Array<Message>;
    /**
     * 
     * @type {Offer}
     * @memberof Chat
     */
    offer: Offer;
    /**
     * 
     * @type {Array<ChatMember>}
     * @memberof Chat
     */
    chatMembers: Array<ChatMember>;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Chat
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface ChatAuthDto
 */
export interface ChatAuthDto {
    /**
     * 
     * @type {string}
     * @memberof ChatAuthDto
     */
    socket_id: string;
    /**
     * 
     * @type {string}
     * @memberof ChatAuthDto
     */
    channel_name: string;
}
/**
 * 
 * @export
 * @interface ChatMember
 */
export interface ChatMember {
    /**
     * 
     * @type {string}
     * @memberof ChatMember
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMember
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMember
     */
    chatId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMember
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatMember
     */
    messagesReadAt: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMember
     */
    messagesDeletedAt: string;
    /**
     * 
     * @type {Customer}
     * @memberof ChatMember
     */
    customer?: Customer;
    /**
     * 
     * @type {Chat}
     * @memberof ChatMember
     */
    chat?: Chat;
    /**
     * 
     * @type {string}
     * @memberof ChatMember
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMember
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMember
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface CreateCategoryCrudDto
 */
export interface CreateCategoryCrudDto {
    /**
     * 
     * @type {OfferType}
     * @memberof CreateCategoryCrudDto
     */
    type: OfferType;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryCrudDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface CreateOfferDto
 */
export interface CreateOfferDto {
    /**
     * 
     * @type {UnitType}
     * @memberof CreateOfferDto
     */
    unitType: UnitType;
    /**
     * 
     * @type {OfferType}
     * @memberof CreateOfferDto
     */
    type: OfferType;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferDto
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfferDto
     */
    photoUrls: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferDto
     */
    unit: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferDto
     */
    price: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfferDto
     */
    categoriesIds: Array<string>;
    /**
     * 
     * @type {Location}
     * @memberof CreateOfferDto
     */
    location?: Location;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferDto
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferDto
     */
    region?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferDto
     */
    isWish: boolean;
}
/**
 * 
 * @export
 * @interface CreateOfferErrorCrudDto
 */
export interface CreateOfferErrorCrudDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOfferErrorCrudDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferErrorCrudDto
     */
    message: string;
}
/**
 * 
 * @export
 * @interface CreateOfferWithoutRateDto
 */
export interface CreateOfferWithoutRateDto {
    /**
     * 
     * @type {UnitType}
     * @memberof CreateOfferWithoutRateDto
     */
    unitType: UnitType;
    /**
     * 
     * @type {OfferType}
     * @memberof CreateOfferWithoutRateDto
     */
    type: OfferType;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferWithoutRateDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferWithoutRateDto
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfferWithoutRateDto
     */
    photoUrls: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferWithoutRateDto
     */
    unit: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferWithoutRateDto
     */
    price: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfferWithoutRateDto
     */
    categoriesIds: Array<string>;
    /**
     * 
     * @type {Location}
     * @memberof CreateOfferWithoutRateDto
     */
    location?: Location;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferWithoutRateDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferWithoutRateDto
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferWithoutRateDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferWithoutRateDto
     */
    region?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferWithoutRateDto
     */
    isWish: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferWithoutRateDto
     */
    duration: number;
}
/**
 * 
 * @export
 * @interface CreatePromotionCrudDto
 */
export interface CreatePromotionCrudDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    photoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    region?: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePromotionCrudDto
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePromotionCrudDto
     */
    aroundRussia: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    completionDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePromotionCrudDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    about_promoter?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePromotionCrudDto
     */
    promoter_token?: string;
}
/**
 * 
 * @export
 * @interface CreateRateCrudDto
 */
export interface CreateRateCrudDto {
    /**
     * 
     * @type {RateType}
     * @memberof CreateRateCrudDto
     */
    type: RateType;
    /**
     * 
     * @type {string}
     * @memberof CreateRateCrudDto
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof CreateRateCrudDto
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof CreateRateCrudDto
     */
    offersCount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateRateCrudDto
     */
    price: number;
}
/**
 * 
 * @export
 * @interface Cursor
 */
export interface Cursor {
    /**
     * 
     * @type {string}
     * @memberof Cursor
     */
    beforeCursor: string | null;
    /**
     * 
     * @type {string}
     * @memberof Cursor
     */
    afterCursor: string | null;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    fullName?: string;
    /**
     * 
     * @type {object}
     * @memberof Customer
     */
    location?: object;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    fcmToken?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    authZeroId?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    lastAuthProvider: CustomerLastAuthProviderEnum;
    /**
     * 
     * @type {object}
     * @memberof Customer
     */
    notificationsSettings: object;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    bannedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    about?: string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    vkUserId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Customer
     */
    viewedPromotionIds: Array<string>;
    /**
     * 
     * @type {Array<Offer>}
     * @memberof Customer
     */
    offers?: Array<Offer>;
    /**
     * 
     * @type {Array<CustomerFavouriteOffer>}
     * @memberof Customer
     */
    customerFavouriteOffers?: Array<CustomerFavouriteOffer>;
    /**
     * 
     * @type {Array<CustomerFavouriteSeller>}
     * @memberof Customer
     */
    customerFavouriteSellers?: Array<CustomerFavouriteSeller>;
    /**
     * 
     * @type {Array<CustomerRate>}
     * @memberof Customer
     */
    customerRates?: Array<CustomerRate>;
    /**
     * 
     * @type {Array<ChatMember>}
     * @memberof Customer
     */
    chatMembers: Array<ChatMember>;
    /**
     * 
     * @type {Array<CustomerNotification>}
     * @memberof Customer
     */
    customerNotifications: Array<CustomerNotification>;
    /**
     * 
     * @type {Array<CustomerReport>}
     * @memberof Customer
     */
    sentReports?: Array<CustomerReport>;
    /**
     * 
     * @type {Array<CustomerReport>}
     * @memberof Customer
     */
    reportsAboutCustomer?: Array<CustomerReport>;
    /**
     * 
     * @type {Array<BlockCustomer>}
     * @memberof Customer
     */
    blockedCustomers: Array<BlockCustomer>;
    /**
     * 
     * @type {Array<BlockCustomer>}
     * @memberof Customer
     */
    blockedByCustomers: Array<BlockCustomer>;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomerLastAuthProviderEnum {
    Sms = 'sms',
    Apple = 'apple',
    Facebook = 'facebook',
    Google = 'google',
    Vk = 'vk'
}

/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    deletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    fullName?: string;
    /**
     * 
     * @type {object}
     * @memberof CustomerDto
     */
    location?: object;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    fcmToken?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    authZeroId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    lastAuthProvider: CustomerDtoLastAuthProviderEnum;
    /**
     * 
     * @type {object}
     * @memberof CustomerDto
     */
    notificationsSettings: object;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    bannedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    about?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    vkUserId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerDto
     */
    viewedPromotionIds: Array<string>;
    /**
     * 
     * @type {Array<Offer>}
     * @memberof CustomerDto
     */
    offers?: Array<Offer>;
    /**
     * 
     * @type {Array<CustomerFavouriteOffer>}
     * @memberof CustomerDto
     */
    customerFavouriteOffers?: Array<CustomerFavouriteOffer>;
    /**
     * 
     * @type {Array<CustomerFavouriteSeller>}
     * @memberof CustomerDto
     */
    customerFavouriteSellers?: Array<CustomerFavouriteSeller>;
    /**
     * 
     * @type {Array<CustomerRate>}
     * @memberof CustomerDto
     */
    customerRates?: Array<CustomerRate>;
    /**
     * 
     * @type {Array<ChatMember>}
     * @memberof CustomerDto
     */
    chatMembers: Array<ChatMember>;
    /**
     * 
     * @type {Array<CustomerNotification>}
     * @memberof CustomerDto
     */
    customerNotifications: Array<CustomerNotification>;
    /**
     * 
     * @type {Array<CustomerReport>}
     * @memberof CustomerDto
     */
    sentReports?: Array<CustomerReport>;
    /**
     * 
     * @type {Array<CustomerReport>}
     * @memberof CustomerDto
     */
    reportsAboutCustomer?: Array<CustomerReport>;
    /**
     * 
     * @type {Array<BlockCustomer>}
     * @memberof CustomerDto
     */
    blockedCustomers: Array<BlockCustomer>;
    /**
     * 
     * @type {Array<BlockCustomer>}
     * @memberof CustomerDto
     */
    blockedByCustomers: Array<BlockCustomer>;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomerDtoLastAuthProviderEnum {
    Sms = 'sms',
    Apple = 'apple',
    Facebook = 'facebook',
    Google = 'google',
    Vk = 'vk'
}

/**
 * 
 * @export
 * @interface CustomerFavouriteOffer
 */
export interface CustomerFavouriteOffer {
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteOffer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteOffer
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteOffer
     */
    offerId: string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerFavouriteOffer
     */
    customer?: Customer;
    /**
     * 
     * @type {Offer}
     * @memberof CustomerFavouriteOffer
     */
    offer?: Offer;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteOffer
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteOffer
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteOffer
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface CustomerFavouriteOffersPaginatedDto
 */
export interface CustomerFavouriteOffersPaginatedDto {
    /**
     * 
     * @type {Array<CustomerFavouriteOffer>}
     * @memberof CustomerFavouriteOffersPaginatedDto
     */
    items: Array<CustomerFavouriteOffer>;
    /**
     * 
     * @type {Cursor}
     * @memberof CustomerFavouriteOffersPaginatedDto
     */
    cursor: Cursor;
}
/**
 * 
 * @export
 * @interface CustomerFavouriteSeller
 */
export interface CustomerFavouriteSeller {
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteSeller
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteSeller
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteSeller
     */
    sellerId: string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerFavouriteSeller
     */
    customer?: Customer;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerFavouriteSeller
     */
    seller?: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteSeller
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteSeller
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFavouriteSeller
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface CustomerFavouriteSellersPaginatedDto
 */
export interface CustomerFavouriteSellersPaginatedDto {
    /**
     * 
     * @type {Array<CustomerFavouriteSeller>}
     * @memberof CustomerFavouriteSellersPaginatedDto
     */
    items: Array<CustomerFavouriteSeller>;
    /**
     * 
     * @type {Cursor}
     * @memberof CustomerFavouriteSellersPaginatedDto
     */
    cursor: Cursor;
}
/**
 * 
 * @export
 * @interface CustomerNotification
 */
export interface CustomerNotification {
    /**
     * 
     * @type {string}
     * @memberof CustomerNotification
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerNotification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerNotification
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerNotification
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerNotification
     */
    notificationType: CustomerNotificationNotificationTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerNotification
     */
    isRead: boolean;
    /**
     * 
     * @type {object}
     * @memberof CustomerNotification
     */
    extraData?: object;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerNotification
     */
    customer: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerNotification
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerNotification
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerNotification
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomerNotificationNotificationTypeEnum {
    NewMessage = 'NEW_MESSAGE',
    OfferExpired = 'OFFER_EXPIRED',
    OfferAccepted = 'OFFER_ACCEPTED',
    OfferRejected = 'OFFER_REJECTED'
}

/**
 * 
 * @export
 * @interface CustomerNotificationsPaginatedDto
 */
export interface CustomerNotificationsPaginatedDto {
    /**
     * 
     * @type {Array<CustomerNotification>}
     * @memberof CustomerNotificationsPaginatedDto
     */
    items: Array<CustomerNotification>;
    /**
     * 
     * @type {Cursor}
     * @memberof CustomerNotificationsPaginatedDto
     */
    cursor: Cursor;
}
/**
 * 
 * @export
 * @interface CustomerRate
 */
export interface CustomerRate {
    /**
     * 
     * @type {string}
     * @memberof CustomerRate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRate
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRate
     */
    rateId: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerRate
     */
    balance: number;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerRate
     */
    customer?: Customer;
    /**
     * 
     * @type {Rate}
     * @memberof CustomerRate
     */
    rate?: Rate;
    /**
     * 
     * @type {string}
     * @memberof CustomerRate
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRate
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRate
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface CustomerRateResponseDto
 */
export interface CustomerRateResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerRateResponseDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRateResponseDto
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRateResponseDto
     */
    deletedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerRateResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRateResponseDto
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerRateResponseDto
     */
    rateId: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerRateResponseDto
     */
    balance: number;
    /**
     * 
     * @type {RateResponseDto}
     * @memberof CustomerRateResponseDto
     */
    rate: RateResponseDto;
}
/**
 * 
 * @export
 * @interface CustomerReport
 */
export interface CustomerReport {
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    senderId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    customerPhone: string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerReport
     */
    sender?: Customer;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerReport
     */
    customer?: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerReport
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface GetManyAdminResponseDto
 */
export interface GetManyAdminResponseDto {
    /**
     * 
     * @type {Array<Admin>}
     * @memberof GetManyAdminResponseDto
     */
    data: Array<Admin>;
    /**
     * 
     * @type {number}
     * @memberof GetManyAdminResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyAdminResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyAdminResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyAdminResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyBlockCustomerResponseDto
 */
export interface GetManyBlockCustomerResponseDto {
    /**
     * 
     * @type {Array<BlockCustomer>}
     * @memberof GetManyBlockCustomerResponseDto
     */
    data: Array<BlockCustomer>;
    /**
     * 
     * @type {number}
     * @memberof GetManyBlockCustomerResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyBlockCustomerResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyBlockCustomerResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyBlockCustomerResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyCategoryResponseDto
 */
export interface GetManyCategoryResponseDto {
    /**
     * 
     * @type {Array<Category>}
     * @memberof GetManyCategoryResponseDto
     */
    data: Array<Category>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCategoryResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyChatResponseDto
 */
export interface GetManyChatResponseDto {
    /**
     * 
     * @type {Array<Chat>}
     * @memberof GetManyChatResponseDto
     */
    data: Array<Chat>;
    /**
     * 
     * @type {number}
     * @memberof GetManyChatResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyChatResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyChatResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyChatResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyCustomerReportResponseDto
 */
export interface GetManyCustomerReportResponseDto {
    /**
     * 
     * @type {Array<CustomerReport>}
     * @memberof GetManyCustomerReportResponseDto
     */
    data: Array<CustomerReport>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerReportResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerReportResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerReportResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerReportResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyCustomerResponseDto
 */
export interface GetManyCustomerResponseDto {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof GetManyCustomerResponseDto
     */
    data: Array<Customer>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyMessageResponseDto
 */
export interface GetManyMessageResponseDto {
    /**
     * 
     * @type {Array<Message>}
     * @memberof GetManyMessageResponseDto
     */
    data: Array<Message>;
    /**
     * 
     * @type {number}
     * @memberof GetManyMessageResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMessageResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMessageResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyMessageResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyOfferErrorResponseDto
 */
export interface GetManyOfferErrorResponseDto {
    /**
     * 
     * @type {Array<OfferError>}
     * @memberof GetManyOfferErrorResponseDto
     */
    data: Array<OfferError>;
    /**
     * 
     * @type {number}
     * @memberof GetManyOfferErrorResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOfferErrorResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOfferErrorResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOfferErrorResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyOfferResponseDto
 */
export interface GetManyOfferResponseDto {
    /**
     * 
     * @type {Array<Offer>}
     * @memberof GetManyOfferResponseDto
     */
    data: Array<Offer>;
    /**
     * 
     * @type {number}
     * @memberof GetManyOfferResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOfferResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOfferResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyOfferResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyPromotionResponseDto
 */
export interface GetManyPromotionResponseDto {
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof GetManyPromotionResponseDto
     */
    data: Array<Promotion>;
    /**
     * 
     * @type {number}
     * @memberof GetManyPromotionResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPromotionResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPromotionResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyPromotionResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GetManyRateResponseDto
 */
export interface GetManyRateResponseDto {
    /**
     * 
     * @type {Array<Rate>}
     * @memberof GetManyRateResponseDto
     */
    data: Array<Rate>;
    /**
     * 
     * @type {number}
     * @memberof GetManyRateResponseDto
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyRateResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyRateResponseDto
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof GetManyRateResponseDto
     */
    pageCount: number;
}
/**
 * 
 * @export
 * @interface GoogleSignInDto
 */
export interface GoogleSignInDto {
    /**
     * 
     * @type {string}
     * @memberof GoogleSignInDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    info?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    error?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    details?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    info?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    error?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    details?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {Point}
     * @memberof Location
     */
    type: LocationTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof Location
     */
    coordinates: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum LocationTypeEnum {
    Point = 'Point'
}

/**
 * 
 * @export
 * @interface ManagerUpdateDto
 */
export interface ManagerUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof ManagerUpdateDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerUpdateDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerUpdateDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerUpdateDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerUpdateDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerUpdateDto
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof ManagerUpdateDto
     */
    feedbackEmail: string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    chatId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    senderId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    readAt: string | null;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    senderName: string;
    /**
     * 
     * @type {Chat}
     * @memberof Message
     */
    chat?: Chat;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface MessagesPaginatedDto
 */
export interface MessagesPaginatedDto {
    /**
     * 
     * @type {Array<Message>}
     * @memberof MessagesPaginatedDto
     */
    items: Array<Message>;
    /**
     * 
     * @type {Cursor}
     * @memberof MessagesPaginatedDto
     */
    cursor: Cursor;
}
/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {object}
     * @memberof Offer
     */
    offerMetaData?: object;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Offer
     */
    photoUrls: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    unit?: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    unitType?: OfferUnitTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    expirationDate: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    status: OfferStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    type: OfferTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof Offer
     */
    location: object;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    district: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    customerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    isWish: boolean;
    /**
     * 
     * @type {Customer}
     * @memberof Offer
     */
    customer?: Customer;
    /**
     * 
     * @type {Array<OfferWithCategory>}
     * @memberof Offer
     */
    offerWithCategories?: Array<OfferWithCategory>;
    /**
     * 
     * @type {Array<CustomerFavouriteOffer>}
     * @memberof Offer
     */
    customerFavouriteOffers?: Array<CustomerFavouriteOffer>;
    /**
     * 
     * @type {Array<Chat>}
     * @memberof Offer
     */
    chats: Array<Chat>;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    deletedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum OfferUnitTypeEnum {
    Grams = 'GRAMS',
    Milliliters = 'MILLILITERS',
    Things = 'THINGS'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferStatusEnum {
    Opened = 'OPENED',
    Closed = 'CLOSED',
    OnModeration = 'ON_MODERATION',
    Rejected = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum OfferTypeEnum {
    Dish = 'DISH',
    Ingredient = 'INGREDIENT'
}

/**
 * 
 * @export
 * @interface OfferError
 */
export interface OfferError {
    /**
     * 
     * @type {string}
     * @memberof OfferError
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferError
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof OfferError
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof OfferError
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OfferError
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof OfferError
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface OfferErrorDto
 */
export interface OfferErrorDto {
    /**
     * 
     * @type {string}
     * @memberof OfferErrorDto
     */
    errorTitle: string;
    /**
     * 
     * @type {string}
     * @memberof OfferErrorDto
     */
    errorMessage: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferStatus {
    Opened = 'OPENED',
    Closed = 'CLOSED',
    OnModeration = 'ON_MODERATION',
    Rejected = 'REJECTED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferType {
    Dish = 'DISH',
    Ingredient = 'INGREDIENT'
}

/**
 * 
 * @export
 * @interface OfferWithCategory
 */
export interface OfferWithCategory {
    /**
     * 
     * @type {string}
     * @memberof OfferWithCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OfferWithCategory
     */
    offerId: string;
    /**
     * 
     * @type {string}
     * @memberof OfferWithCategory
     */
    categoryId: string;
    /**
     * 
     * @type {Offer}
     * @memberof OfferWithCategory
     */
    offer?: Offer;
    /**
     * 
     * @type {Category}
     * @memberof OfferWithCategory
     */
    category?: Category;
    /**
     * 
     * @type {string}
     * @memberof OfferWithCategory
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OfferWithCategory
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof OfferWithCategory
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface OffersPaginatedDto
 */
export interface OffersPaginatedDto {
    /**
     * 
     * @type {Array<Offer>}
     * @memberof OffersPaginatedDto
     */
    items: Array<Offer>;
    /**
     * 
     * @type {Cursor}
     * @memberof OffersPaginatedDto
     */
    cursor: Cursor;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OffersSortType {
    CreatedAt = 'createdAt',
    Price = 'price'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrderType {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface PatchCustomerFcmDto
 */
export interface PatchCustomerFcmDto {
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerFcmDto
     */
    fcmToken: string;
}
/**
 * 
 * @export
 * @interface PatchCustomerProfileDto
 */
export interface PatchCustomerProfileDto {
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerProfileDto
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerProfileDto
     */
    fullName?: string;
    /**
     * 
     * @type {Location}
     * @memberof PatchCustomerProfileDto
     */
    location?: Location;
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerProfileDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerProfileDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerProfileDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerProfileDto
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerProfileDto
     */
    about?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCustomerProfileDto
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface PatchNotificationsSettingsDto
 */
export interface PatchNotificationsSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof PatchNotificationsSettingsDto
     */
    newMessages: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchNotificationsSettingsDto
     */
    expiredOffers: boolean;
}
/**
 * 
 * @export
 * @interface ProcessOfferDto
 */
export interface ProcessOfferDto {
    /**
     * 
     * @type {Array<OfferErrorDto>}
     * @memberof ProcessOfferDto
     */
    errors: Array<OfferErrorDto>;
    /**
     * 
     * @type {string}
     * @memberof ProcessOfferDto
     */
    status: ProcessOfferDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessOfferDtoStatusEnum {
    Opened = 'OPENED',
    Closed = 'CLOSED',
    OnModeration = 'ON_MODERATION',
    Rejected = 'REJECTED'
}

/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    photoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    region: string;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    numberOfViews: number;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    numberOfClicks: number;
    /**
     * 
     * @type {number}
     * @memberof Promotion
     */
    position: number;
    /**
     * 
     * @type {boolean}
     * @memberof Promotion
     */
    aroundRussia: boolean;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    completionDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof Promotion
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    about_promoter: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    promoter_token: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    deletedAt?: string;
}
/**
 * 
 * @export
 * @interface Rate
 */
export interface Rate {
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof Rate
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof Rate
     */
    offersCount: number;
    /**
     * 
     * @type {number}
     * @memberof Rate
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    type: RateTypeEnum;
    /**
     * 
     * @type {Array<CustomerRate>}
     * @memberof Rate
     */
    customerRates?: Array<CustomerRate>;
}

/**
    * @export
    * @enum {string}
    */
export enum RateTypeEnum {
    Dish = 'DISH',
    Ingredient = 'INGREDIENT'
}

/**
 * 
 * @export
 * @interface RateResponseDto
 */
export interface RateResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RateResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RateResponseDto
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof RateResponseDto
     */
    duration: number;
    /**
     * 
     * @type {number}
     * @memberof RateResponseDto
     */
    offersCount: number;
    /**
     * 
     * @type {number}
     * @memberof RateResponseDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof RateResponseDto
     */
    type: RateResponseDtoTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RateResponseDtoTypeEnum {
    Dish = 'DISH',
    Ingredient = 'INGREDIENT'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum RateType {
    Dish = 'DISH',
    Ingredient = 'INGREDIENT'
}

/**
 * 
 * @export
 * @interface ReopenOfferByRateDto
 */
export interface ReopenOfferByRateDto {
    /**
     * 
     * @type {string}
     * @memberof ReopenOfferByRateDto
     */
    rateId: string;
}
/**
 * 
 * @export
 * @interface ReopenOfferDto
 */
export interface ReopenOfferDto {
    /**
     * 
     * @type {number}
     * @memberof ReopenOfferDto
     */
    duration: number;
}
/**
 * 
 * @export
 * @interface SendMessageDto
 */
export interface SendMessageDto {
    /**
     * 
     * @type {string}
     * @memberof SendMessageDto
     */
    message: string;
}
/**
 * 
 * @export
 * @interface SendReportDto
 */
export interface SendReportDto {
    /**
     * 
     * @type {string}
     * @memberof SendReportDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SendReportDto
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof SendReportDto
     */
    customerPhone: string;
}
/**
 * 
 * @export
 * @interface SetViewedQueryDto
 */
export interface SetViewedQueryDto {
    /**
     * 
     * @type {string}
     * @memberof SetViewedQueryDto
     */
    clicked?: string | null;
}
/**
 * 
 * @export
 * @interface SignedUrlBodyDto
 */
export interface SignedUrlBodyDto {
    /**
     * 
     * @type {string}
     * @memberof SignedUrlBodyDto
     */
    filename: string;
}
/**
 * 
 * @export
 * @interface SignedUrlResDto
 */
export interface SignedUrlResDto {
    /**
     * 
     * @type {string}
     * @memberof SignedUrlResDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof SignedUrlResDto
     */
    fileKey: string;
}
/**
 * 
 * @export
 * @interface SigninDto
 */
export interface SigninDto {
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof SigninDto
     */
    smscode: string;
}
/**
 * 
 * @export
 * @interface SmsCodeDto
 */
export interface SmsCodeDto {
    /**
     * 
     * @type {string}
     * @memberof SmsCodeDto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface SwitchSettingsDto
 */
export interface SwitchSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof SwitchSettingsDto
     */
    switchModeration: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UnitType {
    Grams = 'GRAMS',
    Milliliters = 'MILLILITERS',
    Things = 'THINGS'
}

/**
 * 
 * @export
 * @interface UpdateCategoryCrudDto
 */
export interface UpdateCategoryCrudDto {
    /**
     * 
     * @type {OfferType}
     * @memberof UpdateCategoryCrudDto
     */
    type: OfferType;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryCrudDto
     */
    title: string;
}
/**
 * 
 * @export
 * @interface UpdateOfferDto
 */
export interface UpdateOfferDto {
    /**
     * 
     * @type {UnitType}
     * @memberof UpdateOfferDto
     */
    weightType?: UnitType;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferDto
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOfferDto
     */
    photoUrls?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdateOfferDto
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateOfferDto
     */
    price?: number;
    /**
     * 
     * @type {Location}
     * @memberof UpdateOfferDto
     */
    location?: Location;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferDto
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferDto
     */
    region?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOfferDto
     */
    categoriesIds: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateOfferErrorCrudDto
 */
export interface UpdateOfferErrorCrudDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferErrorCrudDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfferErrorCrudDto
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface UpdatePromotionCrudDto
 */
export interface UpdatePromotionCrudDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    photoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    region?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePromotionCrudDto
     */
    position?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePromotionCrudDto
     */
    aroundRussia?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    completionDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePromotionCrudDto
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    about_promoter?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePromotionCrudDto
     */
    promoter_token?: string;
}
/**
 * 
 * @export
 * @interface UpdateRateCrudDto
 */
export interface UpdateRateCrudDto {
    /**
     * 
     * @type {RateType}
     * @memberof UpdateRateCrudDto
     */
    type?: RateType;
    /**
     * 
     * @type {string}
     * @memberof UpdateRateCrudDto
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRateCrudDto
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateRateCrudDto
     */
    offersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateRateCrudDto
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface VkSignInDto
 */
export interface VkSignInDto {
    /**
     * 
     * @type {object}
     * @memberof VkSignInDto
     */
    vkCustomer: object;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckApi(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckApi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheckApi(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: any) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheckApi(options?: any) {
        return DefaultApiFp(this.configuration).healthControllerCheckApi(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelAdminFeaturesApi - axios parameter creator
 * @export
 */
export const V1AdminPanelAdminFeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ban customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturesControllerBanCustomer: async (customerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('adminFeaturesControllerBanCustomer', 'customerId', customerId)
            const localVarPath = `/v1/admin/ban/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unban customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturesControllerUnbanCustomer: async (customerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('adminFeaturesControllerUnbanCustomer', 'customerId', customerId)
            const localVarPath = `/v1/admin/unban/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelAdminFeaturesApi - functional programming interface
 * @export
 */
export const V1AdminPanelAdminFeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelAdminFeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ban customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFeaturesControllerBanCustomer(customerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFeaturesControllerBanCustomer(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unban customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFeaturesControllerUnbanCustomer(customerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFeaturesControllerUnbanCustomer(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelAdminFeaturesApi - factory interface
 * @export
 */
export const V1AdminPanelAdminFeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelAdminFeaturesApiFp(configuration)
    return {
        /**
         * 
         * @summary Ban customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturesControllerBanCustomer(customerId: string, options?: any): AxiosPromise<Customer> {
            return localVarFp.adminFeaturesControllerBanCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unban customer
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturesControllerUnbanCustomer(customerId: string, options?: any): AxiosPromise<Customer> {
            return localVarFp.adminFeaturesControllerUnbanCustomer(customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelAdminFeaturesApi - object-oriented interface
 * @export
 * @class V1AdminPanelAdminFeaturesApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelAdminFeaturesApi extends BaseAPI {
    /**
     * 
     * @summary Ban customer
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelAdminFeaturesApi
     */
    public adminFeaturesControllerBanCustomer(customerId: string, options?: any) {
        return V1AdminPanelAdminFeaturesApiFp(this.configuration).adminFeaturesControllerBanCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unban customer
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelAdminFeaturesApi
     */
    public adminFeaturesControllerUnbanCustomer(customerId: string, options?: any) {
        return V1AdminPanelAdminFeaturesApiFp(this.configuration).adminFeaturesControllerUnbanCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelAdminPanelSettingsApi - axios parameter creator
 * @export
 */
export const V1AdminPanelAdminPanelSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Admin Panel Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPanelSettingsControllerGetSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Switch Admin Panel Settings
         * @param {SwitchSettingsDto} switchSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPanelSettingsControllerSwitchSettings: async (switchSettingsDto: SwitchSettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'switchSettingsDto' is not null or undefined
            assertParamExists('adminPanelSettingsControllerSwitchSettings', 'switchSettingsDto', switchSettingsDto)
            const localVarPath = `/v1/admin/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(switchSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelAdminPanelSettingsApi - functional programming interface
 * @export
 */
export const V1AdminPanelAdminPanelSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelAdminPanelSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Admin Panel Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPanelSettingsControllerGetSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPanelSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPanelSettingsControllerGetSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Switch Admin Panel Settings
         * @param {SwitchSettingsDto} switchSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPanelSettingsControllerSwitchSettings(switchSettingsDto: SwitchSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminPanelSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPanelSettingsControllerSwitchSettings(switchSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelAdminPanelSettingsApi - factory interface
 * @export
 */
export const V1AdminPanelAdminPanelSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelAdminPanelSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Admin Panel Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPanelSettingsControllerGetSettings(options?: any): AxiosPromise<AdminPanelSetting> {
            return localVarFp.adminPanelSettingsControllerGetSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Switch Admin Panel Settings
         * @param {SwitchSettingsDto} switchSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPanelSettingsControllerSwitchSettings(switchSettingsDto: SwitchSettingsDto, options?: any): AxiosPromise<AdminPanelSetting> {
            return localVarFp.adminPanelSettingsControllerSwitchSettings(switchSettingsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelAdminPanelSettingsApi - object-oriented interface
 * @export
 * @class V1AdminPanelAdminPanelSettingsApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelAdminPanelSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get Admin Panel Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelAdminPanelSettingsApi
     */
    public adminPanelSettingsControllerGetSettings(options?: any) {
        return V1AdminPanelAdminPanelSettingsApiFp(this.configuration).adminPanelSettingsControllerGetSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Switch Admin Panel Settings
     * @param {SwitchSettingsDto} switchSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelAdminPanelSettingsApi
     */
    public adminPanelSettingsControllerSwitchSettings(switchSettingsDto: SwitchSettingsDto, options?: any) {
        return V1AdminPanelAdminPanelSettingsApiFp(this.configuration).adminPanelSettingsControllerSwitchSettings(switchSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelBlockCustomersApi - axios parameter creator
 * @export
 */
export const V1AdminPanelBlockCustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple BlockCustomers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseBlockCustomersCrudControllerBlockCustomer: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/block-customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single BlockCustomer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseBlockCustomersCrudControllerBlockCustomer: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseBlockCustomersCrudControllerBlockCustomer', 'id', id)
            const localVarPath = `/v1/admin/block-customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelBlockCustomersApi - functional programming interface
 * @export
 */
export const V1AdminPanelBlockCustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelBlockCustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple BlockCustomers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseBlockCustomersCrudControllerBlockCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyBlockCustomerResponseDto | Array<BlockCustomer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseBlockCustomersCrudControllerBlockCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single BlockCustomer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseBlockCustomersCrudControllerBlockCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockCustomer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseBlockCustomersCrudControllerBlockCustomer(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelBlockCustomersApi - factory interface
 * @export
 */
export const V1AdminPanelBlockCustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelBlockCustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple BlockCustomers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseBlockCustomersCrudControllerBlockCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyBlockCustomerResponseDto | Array<BlockCustomer>> {
            return localVarFp.getManyBaseBlockCustomersCrudControllerBlockCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single BlockCustomer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseBlockCustomersCrudControllerBlockCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<BlockCustomer> {
            return localVarFp.getOneBaseBlockCustomersCrudControllerBlockCustomer(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelBlockCustomersApi - object-oriented interface
 * @export
 * @class V1AdminPanelBlockCustomersApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelBlockCustomersApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple BlockCustomers
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelBlockCustomersApi
     */
    public getManyBaseBlockCustomersCrudControllerBlockCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelBlockCustomersApiFp(this.configuration).getManyBaseBlockCustomersCrudControllerBlockCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single BlockCustomer
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelBlockCustomersApi
     */
    public getOneBaseBlockCustomersCrudControllerBlockCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelBlockCustomersApiFp(this.configuration).getOneBaseBlockCustomersCrudControllerBlockCustomer(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelCategoriesApi - axios parameter creator
 * @export
 */
export const V1AdminPanelCategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Category
         * @param {CreateCategoryCrudDto} createCategoryCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseCategoriesCrudControllerCategory: async (createCategoryCrudDto: CreateCategoryCrudDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCategoryCrudDto' is not null or undefined
            assertParamExists('createOneBaseCategoriesCrudControllerCategory', 'createCategoryCrudDto', createCategoryCrudDto)
            const localVarPath = `/v1/admin/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategoryCrudDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Category
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseCategoriesCrudControllerCategory: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseCategoriesCrudControllerCategory', 'id', id)
            const localVarPath = `/v1/admin/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Categories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseCategoriesCrudControllerCategory: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Category
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseCategoriesCrudControllerCategory: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseCategoriesCrudControllerCategory', 'id', id)
            const localVarPath = `/v1/admin/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Category
         * @param {string} id 
         * @param {UpdateCategoryCrudDto} updateCategoryCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseCategoriesCrudControllerCategory: async (id: string, updateCategoryCrudDto: UpdateCategoryCrudDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseCategoriesCrudControllerCategory', 'id', id)
            // verify required parameter 'updateCategoryCrudDto' is not null or undefined
            assertParamExists('updateOneBaseCategoriesCrudControllerCategory', 'updateCategoryCrudDto', updateCategoryCrudDto)
            const localVarPath = `/v1/admin/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCategoryCrudDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelCategoriesApi - functional programming interface
 * @export
 */
export const V1AdminPanelCategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelCategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Category
         * @param {CreateCategoryCrudDto} createCategoryCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseCategoriesCrudControllerCategory(createCategoryCrudDto: CreateCategoryCrudDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseCategoriesCrudControllerCategory(createCategoryCrudDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Category
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseCategoriesCrudControllerCategory(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseCategoriesCrudControllerCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Categories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseCategoriesCrudControllerCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyCategoryResponseDto | Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseCategoriesCrudControllerCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Category
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseCategoriesCrudControllerCategory(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseCategoriesCrudControllerCategory(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Category
         * @param {string} id 
         * @param {UpdateCategoryCrudDto} updateCategoryCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseCategoriesCrudControllerCategory(id: string, updateCategoryCrudDto: UpdateCategoryCrudDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseCategoriesCrudControllerCategory(id, updateCategoryCrudDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelCategoriesApi - factory interface
 * @export
 */
export const V1AdminPanelCategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelCategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Category
         * @param {CreateCategoryCrudDto} createCategoryCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseCategoriesCrudControllerCategory(createCategoryCrudDto: CreateCategoryCrudDto, options?: any): AxiosPromise<Category> {
            return localVarFp.createOneBaseCategoriesCrudControllerCategory(createCategoryCrudDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Category
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseCategoriesCrudControllerCategory(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseCategoriesCrudControllerCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Categories
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseCategoriesCrudControllerCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyCategoryResponseDto | Array<Category>> {
            return localVarFp.getManyBaseCategoriesCrudControllerCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Category
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseCategoriesCrudControllerCategory(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Category> {
            return localVarFp.getOneBaseCategoriesCrudControllerCategory(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Category
         * @param {string} id 
         * @param {UpdateCategoryCrudDto} updateCategoryCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseCategoriesCrudControllerCategory(id: string, updateCategoryCrudDto: UpdateCategoryCrudDto, options?: any): AxiosPromise<Category> {
            return localVarFp.updateOneBaseCategoriesCrudControllerCategory(id, updateCategoryCrudDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelCategoriesApi - object-oriented interface
 * @export
 * @class V1AdminPanelCategoriesApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelCategoriesApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Category
     * @param {CreateCategoryCrudDto} createCategoryCrudDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCategoriesApi
     */
    public createOneBaseCategoriesCrudControllerCategory(createCategoryCrudDto: CreateCategoryCrudDto, options?: any) {
        return V1AdminPanelCategoriesApiFp(this.configuration).createOneBaseCategoriesCrudControllerCategory(createCategoryCrudDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Category
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCategoriesApi
     */
    public deleteOneBaseCategoriesCrudControllerCategory(id: string, options?: any) {
        return V1AdminPanelCategoriesApiFp(this.configuration).deleteOneBaseCategoriesCrudControllerCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Categories
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCategoriesApi
     */
    public getManyBaseCategoriesCrudControllerCategory(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelCategoriesApiFp(this.configuration).getManyBaseCategoriesCrudControllerCategory(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Category
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCategoriesApi
     */
    public getOneBaseCategoriesCrudControllerCategory(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelCategoriesApiFp(this.configuration).getOneBaseCategoriesCrudControllerCategory(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Category
     * @param {string} id 
     * @param {UpdateCategoryCrudDto} updateCategoryCrudDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCategoriesApi
     */
    public updateOneBaseCategoriesCrudControllerCategory(id: string, updateCategoryCrudDto: UpdateCategoryCrudDto, options?: any) {
        return V1AdminPanelCategoriesApiFp(this.configuration).updateOneBaseCategoriesCrudControllerCategory(id, updateCategoryCrudDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelChatsApi - axios parameter creator
 * @export
 */
export const V1AdminPanelChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a single Chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseChatsCrudControllerChat: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseChatsCrudControllerChat', 'id', id)
            const localVarPath = `/v1/admin/chats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Chats
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseChatsCrudControllerChat: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/chats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Chat
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseChatsCrudControllerChat: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseChatsCrudControllerChat', 'id', id)
            const localVarPath = `/v1/admin/chats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a single Chat
         * @param {string} id 
         * @param {Chat} chat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneBaseChatsCrudControllerChat: async (id: string, chat: Chat, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('replaceOneBaseChatsCrudControllerChat', 'id', id)
            // verify required parameter 'chat' is not null or undefined
            assertParamExists('replaceOneBaseChatsCrudControllerChat', 'chat', chat)
            const localVarPath = `/v1/admin/chats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chat, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelChatsApi - functional programming interface
 * @export
 */
export const V1AdminPanelChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a single Chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseChatsCrudControllerChat(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseChatsCrudControllerChat(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Chats
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseChatsCrudControllerChat(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyChatResponseDto | Array<Chat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseChatsCrudControllerChat(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Chat
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseChatsCrudControllerChat(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseChatsCrudControllerChat(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replace a single Chat
         * @param {string} id 
         * @param {Chat} chat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async replaceOneBaseChatsCrudControllerChat(id: string, chat: Chat, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.replaceOneBaseChatsCrudControllerChat(id, chat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelChatsApi - factory interface
 * @export
 */
export const V1AdminPanelChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelChatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a single Chat
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseChatsCrudControllerChat(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseChatsCrudControllerChat(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Chats
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseChatsCrudControllerChat(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyChatResponseDto | Array<Chat>> {
            return localVarFp.getManyBaseChatsCrudControllerChat(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Chat
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseChatsCrudControllerChat(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Chat> {
            return localVarFp.getOneBaseChatsCrudControllerChat(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a single Chat
         * @param {string} id 
         * @param {Chat} chat 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replaceOneBaseChatsCrudControllerChat(id: string, chat: Chat, options?: any): AxiosPromise<Chat> {
            return localVarFp.replaceOneBaseChatsCrudControllerChat(id, chat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelChatsApi - object-oriented interface
 * @export
 * @class V1AdminPanelChatsApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelChatsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a single Chat
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelChatsApi
     */
    public deleteOneBaseChatsCrudControllerChat(id: string, options?: any) {
        return V1AdminPanelChatsApiFp(this.configuration).deleteOneBaseChatsCrudControllerChat(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Chats
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelChatsApi
     */
    public getManyBaseChatsCrudControllerChat(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelChatsApiFp(this.configuration).getManyBaseChatsCrudControllerChat(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Chat
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelChatsApi
     */
    public getOneBaseChatsCrudControllerChat(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelChatsApiFp(this.configuration).getOneBaseChatsCrudControllerChat(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a single Chat
     * @param {string} id 
     * @param {Chat} chat 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelChatsApi
     */
    public replaceOneBaseChatsCrudControllerChat(id: string, chat: Chat, options?: any) {
        return V1AdminPanelChatsApiFp(this.configuration).replaceOneBaseChatsCrudControllerChat(id, chat, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelCustomerReportsApi - axios parameter creator
 * @export
 */
export const V1AdminPanelCustomerReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a single CustomerReport
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseCustomerReportsCrudControllerCustomerReport: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseCustomerReportsCrudControllerCustomerReport', 'id', id)
            const localVarPath = `/v1/admin/customer-reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple CustomerReports
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseCustomerReportsCrudControllerCustomerReport: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/customer-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single CustomerReport
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseCustomerReportsCrudControllerCustomerReport: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseCustomerReportsCrudControllerCustomerReport', 'id', id)
            const localVarPath = `/v1/admin/customer-reports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelCustomerReportsApi - functional programming interface
 * @export
 */
export const V1AdminPanelCustomerReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelCustomerReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a single CustomerReport
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseCustomerReportsCrudControllerCustomerReport(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseCustomerReportsCrudControllerCustomerReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple CustomerReports
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseCustomerReportsCrudControllerCustomerReport(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyCustomerReportResponseDto | Array<CustomerReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseCustomerReportsCrudControllerCustomerReport(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single CustomerReport
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseCustomerReportsCrudControllerCustomerReport(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseCustomerReportsCrudControllerCustomerReport(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelCustomerReportsApi - factory interface
 * @export
 */
export const V1AdminPanelCustomerReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelCustomerReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a single CustomerReport
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseCustomerReportsCrudControllerCustomerReport(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseCustomerReportsCrudControllerCustomerReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple CustomerReports
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseCustomerReportsCrudControllerCustomerReport(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyCustomerReportResponseDto | Array<CustomerReport>> {
            return localVarFp.getManyBaseCustomerReportsCrudControllerCustomerReport(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single CustomerReport
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseCustomerReportsCrudControllerCustomerReport(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<CustomerReport> {
            return localVarFp.getOneBaseCustomerReportsCrudControllerCustomerReport(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelCustomerReportsApi - object-oriented interface
 * @export
 * @class V1AdminPanelCustomerReportsApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelCustomerReportsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a single CustomerReport
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCustomerReportsApi
     */
    public deleteOneBaseCustomerReportsCrudControllerCustomerReport(id: string, options?: any) {
        return V1AdminPanelCustomerReportsApiFp(this.configuration).deleteOneBaseCustomerReportsCrudControllerCustomerReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple CustomerReports
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCustomerReportsApi
     */
    public getManyBaseCustomerReportsCrudControllerCustomerReport(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelCustomerReportsApiFp(this.configuration).getManyBaseCustomerReportsCrudControllerCustomerReport(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single CustomerReport
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCustomerReportsApi
     */
    public getOneBaseCustomerReportsCrudControllerCustomerReport(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelCustomerReportsApiFp(this.configuration).getOneBaseCustomerReportsCrudControllerCustomerReport(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelCustomersApi - axios parameter creator
 * @export
 */
export const V1AdminPanelCustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Customer
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseCustomersCrudControllerCustomer: async (customer: Customer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('createOneBaseCustomersCrudControllerCustomer', 'customer', customer)
            const localVarPath = `/v1/admin/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseCustomersCrudControllerCustomer: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseCustomersCrudControllerCustomer', 'id', id)
            const localVarPath = `/v1/admin/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseCustomersCrudControllerCustomer: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseCustomersCrudControllerCustomer: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseCustomersCrudControllerCustomer', 'id', id)
            const localVarPath = `/v1/admin/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Customer
         * @param {string} id 
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseCustomersCrudControllerCustomer: async (id: string, customer: Customer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseCustomersCrudControllerCustomer', 'id', id)
            // verify required parameter 'customer' is not null or undefined
            assertParamExists('updateOneBaseCustomersCrudControllerCustomer', 'customer', customer)
            const localVarPath = `/v1/admin/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelCustomersApi - functional programming interface
 * @export
 */
export const V1AdminPanelCustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelCustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Customer
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseCustomersCrudControllerCustomer(customer: Customer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseCustomersCrudControllerCustomer(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseCustomersCrudControllerCustomer(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseCustomersCrudControllerCustomer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseCustomersCrudControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseCustomersCrudControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseCustomersCrudControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseCustomersCrudControllerCustomer(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Customer
         * @param {string} id 
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseCustomersCrudControllerCustomer(id: string, customer: Customer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseCustomersCrudControllerCustomer(id, customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelCustomersApi - factory interface
 * @export
 */
export const V1AdminPanelCustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelCustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Customer
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseCustomersCrudControllerCustomer(customer: Customer, options?: any): AxiosPromise<Customer> {
            return localVarFp.createOneBaseCustomersCrudControllerCustomer(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Customer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseCustomersCrudControllerCustomer(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseCustomersCrudControllerCustomer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseCustomersCrudControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<object> {
            return localVarFp.getManyBaseCustomersCrudControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseCustomersCrudControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Customer> {
            return localVarFp.getOneBaseCustomersCrudControllerCustomer(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Customer
         * @param {string} id 
         * @param {Customer} customer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseCustomersCrudControllerCustomer(id: string, customer: Customer, options?: any): AxiosPromise<Customer> {
            return localVarFp.updateOneBaseCustomersCrudControllerCustomer(id, customer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelCustomersApi - object-oriented interface
 * @export
 * @class V1AdminPanelCustomersApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelCustomersApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Customer
     * @param {Customer} customer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCustomersApi
     */
    public createOneBaseCustomersCrudControllerCustomer(customer: Customer, options?: any) {
        return V1AdminPanelCustomersApiFp(this.configuration).createOneBaseCustomersCrudControllerCustomer(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Customer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCustomersApi
     */
    public deleteOneBaseCustomersCrudControllerCustomer(id: string, options?: any) {
        return V1AdminPanelCustomersApiFp(this.configuration).deleteOneBaseCustomersCrudControllerCustomer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Customers
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCustomersApi
     */
    public getManyBaseCustomersCrudControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelCustomersApiFp(this.configuration).getManyBaseCustomersCrudControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Customer
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCustomersApi
     */
    public getOneBaseCustomersCrudControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelCustomersApiFp(this.configuration).getOneBaseCustomersCrudControllerCustomer(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Customer
     * @param {string} id 
     * @param {Customer} customer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelCustomersApi
     */
    public updateOneBaseCustomersCrudControllerCustomer(id: string, customer: Customer, options?: any) {
        return V1AdminPanelCustomersApiFp(this.configuration).updateOneBaseCustomersCrudControllerCustomer(id, customer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelManagersApi - axios parameter creator
 * @export
 */
export const V1AdminPanelManagersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Admin
         * @param {AdminCreateDto} adminCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminsCrudControllerAdmin: async (adminCreateDto: AdminCreateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateDto' is not null or undefined
            assertParamExists('createOneBaseAdminsCrudControllerAdmin', 'adminCreateDto', adminCreateDto)
            const localVarPath = `/v1/admin/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Admin
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminsCrudControllerAdmin: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminsCrudControllerAdmin', 'id', id)
            const localVarPath = `/v1/admin/managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Admins
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminsCrudControllerAdmin: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Admin
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminsCrudControllerAdmin: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminsCrudControllerAdmin', 'id', id)
            const localVarPath = `/v1/admin/managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Admin
         * @param {string} id 
         * @param {AdminUpdateDto} adminUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminsCrudControllerAdmin: async (id: string, adminUpdateDto: AdminUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminsCrudControllerAdmin', 'id', id)
            // verify required parameter 'adminUpdateDto' is not null or undefined
            assertParamExists('updateOneBaseAdminsCrudControllerAdmin', 'adminUpdateDto', adminUpdateDto)
            const localVarPath = `/v1/admin/managers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelManagersApi - functional programming interface
 * @export
 */
export const V1AdminPanelManagersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelManagersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Admin
         * @param {AdminCreateDto} adminCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminsCrudControllerAdmin(adminCreateDto: AdminCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminsCrudControllerAdmin(adminCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Admin
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminsCrudControllerAdmin(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminsCrudControllerAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Admins
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminsCrudControllerAdmin(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyAdminResponseDto | Array<Admin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminsCrudControllerAdmin(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Admin
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminsCrudControllerAdmin(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminsCrudControllerAdmin(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Admin
         * @param {string} id 
         * @param {AdminUpdateDto} adminUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminsCrudControllerAdmin(id: string, adminUpdateDto: AdminUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminsCrudControllerAdmin(id, adminUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelManagersApi - factory interface
 * @export
 */
export const V1AdminPanelManagersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelManagersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Admin
         * @param {AdminCreateDto} adminCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminsCrudControllerAdmin(adminCreateDto: AdminCreateDto, options?: any): AxiosPromise<Admin> {
            return localVarFp.createOneBaseAdminsCrudControllerAdmin(adminCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Admin
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminsCrudControllerAdmin(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminsCrudControllerAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Admins
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminsCrudControllerAdmin(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyAdminResponseDto | Array<Admin>> {
            return localVarFp.getManyBaseAdminsCrudControllerAdmin(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Admin
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminsCrudControllerAdmin(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Admin> {
            return localVarFp.getOneBaseAdminsCrudControllerAdmin(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Admin
         * @param {string} id 
         * @param {AdminUpdateDto} adminUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminsCrudControllerAdmin(id: string, adminUpdateDto: AdminUpdateDto, options?: any): AxiosPromise<Admin> {
            return localVarFp.updateOneBaseAdminsCrudControllerAdmin(id, adminUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelManagersApi - object-oriented interface
 * @export
 * @class V1AdminPanelManagersApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelManagersApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Admin
     * @param {AdminCreateDto} adminCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelManagersApi
     */
    public createOneBaseAdminsCrudControllerAdmin(adminCreateDto: AdminCreateDto, options?: any) {
        return V1AdminPanelManagersApiFp(this.configuration).createOneBaseAdminsCrudControllerAdmin(adminCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Admin
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelManagersApi
     */
    public deleteOneBaseAdminsCrudControllerAdmin(id: string, options?: any) {
        return V1AdminPanelManagersApiFp(this.configuration).deleteOneBaseAdminsCrudControllerAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Admins
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelManagersApi
     */
    public getManyBaseAdminsCrudControllerAdmin(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelManagersApiFp(this.configuration).getManyBaseAdminsCrudControllerAdmin(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Admin
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelManagersApi
     */
    public getOneBaseAdminsCrudControllerAdmin(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelManagersApiFp(this.configuration).getOneBaseAdminsCrudControllerAdmin(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Admin
     * @param {string} id 
     * @param {AdminUpdateDto} adminUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelManagersApi
     */
    public updateOneBaseAdminsCrudControllerAdmin(id: string, adminUpdateDto: AdminUpdateDto, options?: any) {
        return V1AdminPanelManagersApiFp(this.configuration).updateOneBaseAdminsCrudControllerAdmin(id, adminUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelMessagesApi - axios parameter creator
 * @export
 */
export const V1AdminPanelMessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple Messages
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseMessagesCrudControllerMessage: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Message
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseMessagesCrudControllerMessage: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseMessagesCrudControllerMessage', 'id', id)
            const localVarPath = `/v1/admin/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelMessagesApi - functional programming interface
 * @export
 */
export const V1AdminPanelMessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelMessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Messages
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseMessagesCrudControllerMessage(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyMessageResponseDto | Array<Message>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseMessagesCrudControllerMessage(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Message
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseMessagesCrudControllerMessage(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseMessagesCrudControllerMessage(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelMessagesApi - factory interface
 * @export
 */
export const V1AdminPanelMessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelMessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple Messages
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseMessagesCrudControllerMessage(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyMessageResponseDto | Array<Message>> {
            return localVarFp.getManyBaseMessagesCrudControllerMessage(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Message
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseMessagesCrudControllerMessage(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Message> {
            return localVarFp.getOneBaseMessagesCrudControllerMessage(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelMessagesApi - object-oriented interface
 * @export
 * @class V1AdminPanelMessagesApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelMessagesApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple Messages
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelMessagesApi
     */
    public getManyBaseMessagesCrudControllerMessage(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelMessagesApiFp(this.configuration).getManyBaseMessagesCrudControllerMessage(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Message
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelMessagesApi
     */
    public getOneBaseMessagesCrudControllerMessage(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelMessagesApiFp(this.configuration).getOneBaseMessagesCrudControllerMessage(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelOfferErrorApi - axios parameter creator
 * @export
 */
export const V1AdminPanelOfferErrorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single OfferError
         * @param {CreateOfferErrorCrudDto} createOfferErrorCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseOfferErorrsCrudControllerOfferError: async (createOfferErrorCrudDto: CreateOfferErrorCrudDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfferErrorCrudDto' is not null or undefined
            assertParamExists('createOneBaseOfferErorrsCrudControllerOfferError', 'createOfferErrorCrudDto', createOfferErrorCrudDto)
            const localVarPath = `/v1/admin/offer-errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferErrorCrudDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single OfferError
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseOfferErorrsCrudControllerOfferError: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseOfferErorrsCrudControllerOfferError', 'id', id)
            const localVarPath = `/v1/admin/offer-errors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple OfferErrors
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseOfferErorrsCrudControllerOfferError: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/offer-errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single OfferError
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseOfferErorrsCrudControllerOfferError: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseOfferErorrsCrudControllerOfferError', 'id', id)
            const localVarPath = `/v1/admin/offer-errors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single OfferError
         * @param {string} id 
         * @param {UpdateOfferErrorCrudDto} updateOfferErrorCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseOfferErorrsCrudControllerOfferError: async (id: string, updateOfferErrorCrudDto: UpdateOfferErrorCrudDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseOfferErorrsCrudControllerOfferError', 'id', id)
            // verify required parameter 'updateOfferErrorCrudDto' is not null or undefined
            assertParamExists('updateOneBaseOfferErorrsCrudControllerOfferError', 'updateOfferErrorCrudDto', updateOfferErrorCrudDto)
            const localVarPath = `/v1/admin/offer-errors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOfferErrorCrudDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelOfferErrorApi - functional programming interface
 * @export
 */
export const V1AdminPanelOfferErrorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelOfferErrorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single OfferError
         * @param {CreateOfferErrorCrudDto} createOfferErrorCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseOfferErorrsCrudControllerOfferError(createOfferErrorCrudDto: CreateOfferErrorCrudDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferError>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseOfferErorrsCrudControllerOfferError(createOfferErrorCrudDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single OfferError
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseOfferErorrsCrudControllerOfferError(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseOfferErorrsCrudControllerOfferError(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple OfferErrors
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseOfferErorrsCrudControllerOfferError(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyOfferErrorResponseDto | Array<OfferError>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseOfferErorrsCrudControllerOfferError(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single OfferError
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseOfferErorrsCrudControllerOfferError(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferError>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseOfferErorrsCrudControllerOfferError(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single OfferError
         * @param {string} id 
         * @param {UpdateOfferErrorCrudDto} updateOfferErrorCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseOfferErorrsCrudControllerOfferError(id: string, updateOfferErrorCrudDto: UpdateOfferErrorCrudDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferError>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseOfferErorrsCrudControllerOfferError(id, updateOfferErrorCrudDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelOfferErrorApi - factory interface
 * @export
 */
export const V1AdminPanelOfferErrorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelOfferErrorApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single OfferError
         * @param {CreateOfferErrorCrudDto} createOfferErrorCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseOfferErorrsCrudControllerOfferError(createOfferErrorCrudDto: CreateOfferErrorCrudDto, options?: any): AxiosPromise<OfferError> {
            return localVarFp.createOneBaseOfferErorrsCrudControllerOfferError(createOfferErrorCrudDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single OfferError
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseOfferErorrsCrudControllerOfferError(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseOfferErorrsCrudControllerOfferError(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple OfferErrors
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseOfferErorrsCrudControllerOfferError(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyOfferErrorResponseDto | Array<OfferError>> {
            return localVarFp.getManyBaseOfferErorrsCrudControllerOfferError(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single OfferError
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseOfferErorrsCrudControllerOfferError(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<OfferError> {
            return localVarFp.getOneBaseOfferErorrsCrudControllerOfferError(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single OfferError
         * @param {string} id 
         * @param {UpdateOfferErrorCrudDto} updateOfferErrorCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseOfferErorrsCrudControllerOfferError(id: string, updateOfferErrorCrudDto: UpdateOfferErrorCrudDto, options?: any): AxiosPromise<OfferError> {
            return localVarFp.updateOneBaseOfferErorrsCrudControllerOfferError(id, updateOfferErrorCrudDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelOfferErrorApi - object-oriented interface
 * @export
 * @class V1AdminPanelOfferErrorApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelOfferErrorApi extends BaseAPI {
    /**
     * 
     * @summary Create a single OfferError
     * @param {CreateOfferErrorCrudDto} createOfferErrorCrudDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOfferErrorApi
     */
    public createOneBaseOfferErorrsCrudControllerOfferError(createOfferErrorCrudDto: CreateOfferErrorCrudDto, options?: any) {
        return V1AdminPanelOfferErrorApiFp(this.configuration).createOneBaseOfferErorrsCrudControllerOfferError(createOfferErrorCrudDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single OfferError
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOfferErrorApi
     */
    public deleteOneBaseOfferErorrsCrudControllerOfferError(id: string, options?: any) {
        return V1AdminPanelOfferErrorApiFp(this.configuration).deleteOneBaseOfferErorrsCrudControllerOfferError(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple OfferErrors
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOfferErrorApi
     */
    public getManyBaseOfferErorrsCrudControllerOfferError(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelOfferErrorApiFp(this.configuration).getManyBaseOfferErorrsCrudControllerOfferError(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single OfferError
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOfferErrorApi
     */
    public getOneBaseOfferErorrsCrudControllerOfferError(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelOfferErrorApiFp(this.configuration).getOneBaseOfferErorrsCrudControllerOfferError(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single OfferError
     * @param {string} id 
     * @param {UpdateOfferErrorCrudDto} updateOfferErrorCrudDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOfferErrorApi
     */
    public updateOneBaseOfferErorrsCrudControllerOfferError(id: string, updateOfferErrorCrudDto: UpdateOfferErrorCrudDto, options?: any) {
        return V1AdminPanelOfferErrorApiFp(this.configuration).updateOneBaseOfferErorrsCrudControllerOfferError(id, updateOfferErrorCrudDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelOffersApi - axios parameter creator
 * @export
 */
export const V1AdminPanelOffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Offer
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseOffersCrudControllerOffer: async (offer: Offer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offer' is not null or undefined
            assertParamExists('createOneBaseOffersCrudControllerOffer', 'offer', offer)
            const localVarPath = `/v1/admin/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseOffersCrudControllerOffer: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseOffersCrudControllerOffer', 'id', id)
            const localVarPath = `/v1/admin/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Offers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseOffersCrudControllerOffer: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Offer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseOffersCrudControllerOffer: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseOffersCrudControllerOffer', 'id', id)
            const localVarPath = `/v1/admin/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extend offers for a month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersCrudControllerExtendForMounth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/offers/extend-for-mounth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process offer
         * @param {string} id 
         * @param {ProcessOfferDto} processOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersCrudControllerProcessOffer: async (id: string, processOfferDto: ProcessOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('offersCrudControllerProcessOffer', 'id', id)
            // verify required parameter 'processOfferDto' is not null or undefined
            assertParamExists('offersCrudControllerProcessOffer', 'processOfferDto', processOfferDto)
            const localVarPath = `/v1/admin/offers/process/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Offer
         * @param {string} id 
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseOffersCrudControllerOffer: async (id: string, offer: Offer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseOffersCrudControllerOffer', 'id', id)
            // verify required parameter 'offer' is not null or undefined
            assertParamExists('updateOneBaseOffersCrudControllerOffer', 'offer', offer)
            const localVarPath = `/v1/admin/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(offer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelOffersApi - functional programming interface
 * @export
 */
export const V1AdminPanelOffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelOffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Offer
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseOffersCrudControllerOffer(offer: Offer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseOffersCrudControllerOffer(offer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseOffersCrudControllerOffer(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseOffersCrudControllerOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Offers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseOffersCrudControllerOffer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseOffersCrudControllerOffer(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Offer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseOffersCrudControllerOffer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseOffersCrudControllerOffer(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Extend offers for a month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersCrudControllerExtendForMounth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersCrudControllerExtendForMounth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Process offer
         * @param {string} id 
         * @param {ProcessOfferDto} processOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersCrudControllerProcessOffer(id: string, processOfferDto: ProcessOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersCrudControllerProcessOffer(id, processOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Offer
         * @param {string} id 
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseOffersCrudControllerOffer(id: string, offer: Offer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseOffersCrudControllerOffer(id, offer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelOffersApi - factory interface
 * @export
 */
export const V1AdminPanelOffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelOffersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Offer
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseOffersCrudControllerOffer(offer: Offer, options?: any): AxiosPromise<Offer> {
            return localVarFp.createOneBaseOffersCrudControllerOffer(offer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseOffersCrudControllerOffer(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseOffersCrudControllerOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Offers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseOffersCrudControllerOffer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<object> {
            return localVarFp.getManyBaseOffersCrudControllerOffer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Offer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseOffersCrudControllerOffer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Offer> {
            return localVarFp.getOneBaseOffersCrudControllerOffer(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extend offers for a month
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersCrudControllerExtendForMounth(options?: any): AxiosPromise<object> {
            return localVarFp.offersCrudControllerExtendForMounth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process offer
         * @param {string} id 
         * @param {ProcessOfferDto} processOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersCrudControllerProcessOffer(id: string, processOfferDto: ProcessOfferDto, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersCrudControllerProcessOffer(id, processOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Offer
         * @param {string} id 
         * @param {Offer} offer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseOffersCrudControllerOffer(id: string, offer: Offer, options?: any): AxiosPromise<Offer> {
            return localVarFp.updateOneBaseOffersCrudControllerOffer(id, offer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelOffersApi - object-oriented interface
 * @export
 * @class V1AdminPanelOffersApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelOffersApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Offer
     * @param {Offer} offer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOffersApi
     */
    public createOneBaseOffersCrudControllerOffer(offer: Offer, options?: any) {
        return V1AdminPanelOffersApiFp(this.configuration).createOneBaseOffersCrudControllerOffer(offer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOffersApi
     */
    public deleteOneBaseOffersCrudControllerOffer(id: string, options?: any) {
        return V1AdminPanelOffersApiFp(this.configuration).deleteOneBaseOffersCrudControllerOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Offers
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOffersApi
     */
    public getManyBaseOffersCrudControllerOffer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelOffersApiFp(this.configuration).getManyBaseOffersCrudControllerOffer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Offer
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOffersApi
     */
    public getOneBaseOffersCrudControllerOffer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelOffersApiFp(this.configuration).getOneBaseOffersCrudControllerOffer(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Extend offers for a month
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOffersApi
     */
    public offersCrudControllerExtendForMounth(options?: any) {
        return V1AdminPanelOffersApiFp(this.configuration).offersCrudControllerExtendForMounth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process offer
     * @param {string} id 
     * @param {ProcessOfferDto} processOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOffersApi
     */
    public offersCrudControllerProcessOffer(id: string, processOfferDto: ProcessOfferDto, options?: any) {
        return V1AdminPanelOffersApiFp(this.configuration).offersCrudControllerProcessOffer(id, processOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Offer
     * @param {string} id 
     * @param {Offer} offer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelOffersApi
     */
    public updateOneBaseOffersCrudControllerOffer(id: string, offer: Offer, options?: any) {
        return V1AdminPanelOffersApiFp(this.configuration).updateOneBaseOffersCrudControllerOffer(id, offer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelPromotionsApi - axios parameter creator
 * @export
 */
export const V1AdminPanelPromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Promotion
         * @param {CreatePromotionCrudDto} createPromotionCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBasePromotionsCrudControllerPromotion: async (createPromotionCrudDto: CreatePromotionCrudDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPromotionCrudDto' is not null or undefined
            assertParamExists('createOneBasePromotionsCrudControllerPromotion', 'createPromotionCrudDto', createPromotionCrudDto)
            const localVarPath = `/v1/admin/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPromotionCrudDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Promotion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBasePromotionsCrudControllerPromotion: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBasePromotionsCrudControllerPromotion', 'id', id)
            const localVarPath = `/v1/admin/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Promotions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBasePromotionsCrudControllerPromotion: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/promotions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Promotion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBasePromotionsCrudControllerPromotion: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBasePromotionsCrudControllerPromotion', 'id', id)
            const localVarPath = `/v1/admin/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create signed url to upload promotion photo
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsCrudControllerCreateSignedUrlForPhoto: async (signedUrlBodyDto: SignedUrlBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signedUrlBodyDto' is not null or undefined
            assertParamExists('promotionsCrudControllerCreateSignedUrlForPhoto', 'signedUrlBodyDto', signedUrlBodyDto)
            const localVarPath = `/v1/admin/promotions/create-promotion-photo-signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedUrlBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Promotion
         * @param {string} id 
         * @param {UpdatePromotionCrudDto} updatePromotionCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBasePromotionsCrudControllerPromotion: async (id: string, updatePromotionCrudDto: UpdatePromotionCrudDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBasePromotionsCrudControllerPromotion', 'id', id)
            // verify required parameter 'updatePromotionCrudDto' is not null or undefined
            assertParamExists('updateOneBasePromotionsCrudControllerPromotion', 'updatePromotionCrudDto', updatePromotionCrudDto)
            const localVarPath = `/v1/admin/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePromotionCrudDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelPromotionsApi - functional programming interface
 * @export
 */
export const V1AdminPanelPromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelPromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Promotion
         * @param {CreatePromotionCrudDto} createPromotionCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBasePromotionsCrudControllerPromotion(createPromotionCrudDto: CreatePromotionCrudDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBasePromotionsCrudControllerPromotion(createPromotionCrudDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Promotion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBasePromotionsCrudControllerPromotion(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBasePromotionsCrudControllerPromotion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Promotions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBasePromotionsCrudControllerPromotion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBasePromotionsCrudControllerPromotion(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Promotion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBasePromotionsCrudControllerPromotion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBasePromotionsCrudControllerPromotion(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create signed url to upload promotion photo
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsCrudControllerCreateSignedUrlForPhoto(signedUrlBodyDto: SignedUrlBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsCrudControllerCreateSignedUrlForPhoto(signedUrlBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Promotion
         * @param {string} id 
         * @param {UpdatePromotionCrudDto} updatePromotionCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBasePromotionsCrudControllerPromotion(id: string, updatePromotionCrudDto: UpdatePromotionCrudDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBasePromotionsCrudControllerPromotion(id, updatePromotionCrudDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelPromotionsApi - factory interface
 * @export
 */
export const V1AdminPanelPromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelPromotionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Promotion
         * @param {CreatePromotionCrudDto} createPromotionCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBasePromotionsCrudControllerPromotion(createPromotionCrudDto: CreatePromotionCrudDto, options?: any): AxiosPromise<Promotion> {
            return localVarFp.createOneBasePromotionsCrudControllerPromotion(createPromotionCrudDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Promotion
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBasePromotionsCrudControllerPromotion(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBasePromotionsCrudControllerPromotion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Promotions
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBasePromotionsCrudControllerPromotion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<object> {
            return localVarFp.getManyBasePromotionsCrudControllerPromotion(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Promotion
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBasePromotionsCrudControllerPromotion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Promotion> {
            return localVarFp.getOneBasePromotionsCrudControllerPromotion(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create signed url to upload promotion photo
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsCrudControllerCreateSignedUrlForPhoto(signedUrlBodyDto: SignedUrlBodyDto, options?: any): AxiosPromise<SignedUrlResDto> {
            return localVarFp.promotionsCrudControllerCreateSignedUrlForPhoto(signedUrlBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Promotion
         * @param {string} id 
         * @param {UpdatePromotionCrudDto} updatePromotionCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBasePromotionsCrudControllerPromotion(id: string, updatePromotionCrudDto: UpdatePromotionCrudDto, options?: any): AxiosPromise<Promotion> {
            return localVarFp.updateOneBasePromotionsCrudControllerPromotion(id, updatePromotionCrudDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelPromotionsApi - object-oriented interface
 * @export
 * @class V1AdminPanelPromotionsApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelPromotionsApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Promotion
     * @param {CreatePromotionCrudDto} createPromotionCrudDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelPromotionsApi
     */
    public createOneBasePromotionsCrudControllerPromotion(createPromotionCrudDto: CreatePromotionCrudDto, options?: any) {
        return V1AdminPanelPromotionsApiFp(this.configuration).createOneBasePromotionsCrudControllerPromotion(createPromotionCrudDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Promotion
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelPromotionsApi
     */
    public deleteOneBasePromotionsCrudControllerPromotion(id: string, options?: any) {
        return V1AdminPanelPromotionsApiFp(this.configuration).deleteOneBasePromotionsCrudControllerPromotion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Promotions
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelPromotionsApi
     */
    public getManyBasePromotionsCrudControllerPromotion(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelPromotionsApiFp(this.configuration).getManyBasePromotionsCrudControllerPromotion(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Promotion
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelPromotionsApi
     */
    public getOneBasePromotionsCrudControllerPromotion(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelPromotionsApiFp(this.configuration).getOneBasePromotionsCrudControllerPromotion(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create signed url to upload promotion photo
     * @param {SignedUrlBodyDto} signedUrlBodyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelPromotionsApi
     */
    public promotionsCrudControllerCreateSignedUrlForPhoto(signedUrlBodyDto: SignedUrlBodyDto, options?: any) {
        return V1AdminPanelPromotionsApiFp(this.configuration).promotionsCrudControllerCreateSignedUrlForPhoto(signedUrlBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Promotion
     * @param {string} id 
     * @param {UpdatePromotionCrudDto} updatePromotionCrudDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelPromotionsApi
     */
    public updateOneBasePromotionsCrudControllerPromotion(id: string, updatePromotionCrudDto: UpdatePromotionCrudDto, options?: any) {
        return V1AdminPanelPromotionsApiFp(this.configuration).updateOneBasePromotionsCrudControllerPromotion(id, updatePromotionCrudDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminPanelRatesApi - axios parameter creator
 * @export
 */
export const V1AdminPanelRatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single Rate
         * @param {CreateRateCrudDto} createRateCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseRatesCrudControllerRate: async (createRateCrudDto: CreateRateCrudDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRateCrudDto' is not null or undefined
            assertParamExists('createOneBaseRatesCrudControllerRate', 'createRateCrudDto', createRateCrudDto)
            const localVarPath = `/v1/admin/rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRateCrudDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single Rate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseRatesCrudControllerRate: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseRatesCrudControllerRate', 'id', id)
            const localVarPath = `/v1/admin/rates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Rates
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseRatesCrudControllerRate: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Rate
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseRatesCrudControllerRate: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseRatesCrudControllerRate', 'id', id)
            const localVarPath = `/v1/admin/rates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single Rate
         * @param {string} id 
         * @param {UpdateRateCrudDto} updateRateCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseRatesCrudControllerRate: async (id: string, updateRateCrudDto: UpdateRateCrudDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseRatesCrudControllerRate', 'id', id)
            // verify required parameter 'updateRateCrudDto' is not null or undefined
            assertParamExists('updateOneBaseRatesCrudControllerRate', 'updateRateCrudDto', updateRateCrudDto)
            const localVarPath = `/v1/admin/rates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRateCrudDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminPanelRatesApi - functional programming interface
 * @export
 */
export const V1AdminPanelRatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminPanelRatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single Rate
         * @param {CreateRateCrudDto} createRateCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseRatesCrudControllerRate(createRateCrudDto: CreateRateCrudDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseRatesCrudControllerRate(createRateCrudDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single Rate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseRatesCrudControllerRate(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseRatesCrudControllerRate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Rates
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseRatesCrudControllerRate(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyRateResponseDto | Array<Rate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseRatesCrudControllerRate(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Rate
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseRatesCrudControllerRate(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseRatesCrudControllerRate(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single Rate
         * @param {string} id 
         * @param {UpdateRateCrudDto} updateRateCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseRatesCrudControllerRate(id: string, updateRateCrudDto: UpdateRateCrudDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseRatesCrudControllerRate(id, updateRateCrudDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminPanelRatesApi - factory interface
 * @export
 */
export const V1AdminPanelRatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminPanelRatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single Rate
         * @param {CreateRateCrudDto} createRateCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseRatesCrudControllerRate(createRateCrudDto: CreateRateCrudDto, options?: any): AxiosPromise<Rate> {
            return localVarFp.createOneBaseRatesCrudControllerRate(createRateCrudDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single Rate
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseRatesCrudControllerRate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseRatesCrudControllerRate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Rates
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseRatesCrudControllerRate(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyRateResponseDto | Array<Rate>> {
            return localVarFp.getManyBaseRatesCrudControllerRate(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Rate
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseRatesCrudControllerRate(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Rate> {
            return localVarFp.getOneBaseRatesCrudControllerRate(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single Rate
         * @param {string} id 
         * @param {UpdateRateCrudDto} updateRateCrudDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseRatesCrudControllerRate(id: string, updateRateCrudDto: UpdateRateCrudDto, options?: any): AxiosPromise<Rate> {
            return localVarFp.updateOneBaseRatesCrudControllerRate(id, updateRateCrudDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminPanelRatesApi - object-oriented interface
 * @export
 * @class V1AdminPanelRatesApi
 * @extends {BaseAPI}
 */
export class V1AdminPanelRatesApi extends BaseAPI {
    /**
     * 
     * @summary Create a single Rate
     * @param {CreateRateCrudDto} createRateCrudDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelRatesApi
     */
    public createOneBaseRatesCrudControllerRate(createRateCrudDto: CreateRateCrudDto, options?: any) {
        return V1AdminPanelRatesApiFp(this.configuration).createOneBaseRatesCrudControllerRate(createRateCrudDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single Rate
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelRatesApi
     */
    public deleteOneBaseRatesCrudControllerRate(id: string, options?: any) {
        return V1AdminPanelRatesApiFp(this.configuration).deleteOneBaseRatesCrudControllerRate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Rates
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelRatesApi
     */
    public getManyBaseRatesCrudControllerRate(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any) {
        return V1AdminPanelRatesApiFp(this.configuration).getManyBaseRatesCrudControllerRate(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Rate
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelRatesApi
     */
    public getOneBaseRatesCrudControllerRate(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any) {
        return V1AdminPanelRatesApiFp(this.configuration).getOneBaseRatesCrudControllerRate(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single Rate
     * @param {string} id 
     * @param {UpdateRateCrudDto} updateRateCrudDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminPanelRatesApi
     */
    public updateOneBaseRatesCrudControllerRate(id: string, updateRateCrudDto: UpdateRateCrudDto, options?: any) {
        return V1AdminPanelRatesApiFp(this.configuration).updateOneBaseRatesCrudControllerRate(id, updateRateCrudDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1AdminsApi - axios parameter creator
 * @export
 */
export const V1AdminsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check admin email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerEmailIsUniq: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('adminsControllerEmailIsUniq', 'email', email)
            const localVarPath = `/v1/admins/email-is-uniq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerGetMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admins/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} city 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerGetModeratorEmail: async (city: string, region: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'city' is not null or undefined
            assertParamExists('adminsControllerGetModeratorEmail', 'city', city)
            // verify required parameter 'region' is not null or undefined
            assertParamExists('adminsControllerGetModeratorEmail', 'region', region)
            const localVarPath = `/v1/admins/moderator-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in via email/password
         * @param {AdminsSignInBodyDto} adminsSignInBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerSignIn: async (adminsSignInBodyDto: AdminsSignInBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminsSignInBodyDto' is not null or undefined
            assertParamExists('adminsControllerSignIn', 'adminsSignInBodyDto', adminsSignInBodyDto)
            const localVarPath = `/v1/admins/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminsSignInBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1AdminsApi - functional programming interface
 * @export
 */
export const V1AdminsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1AdminsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check admin email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerEmailIsUniq(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerEmailIsUniq(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerGetMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} city 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerGetModeratorEmail(city: string, region: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerGetModeratorEmail(city, region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign in via email/password
         * @param {AdminsSignInBodyDto} adminsSignInBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminsControllerSignIn(adminsSignInBodyDto: AdminsSignInBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthAdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminsControllerSignIn(adminsSignInBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1AdminsApi - factory interface
 * @export
 */
export const V1AdminsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1AdminsApiFp(configuration)
    return {
        /**
         * 
         * @summary Check admin email for uniqueness
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerEmailIsUniq(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.adminsControllerEmailIsUniq(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerGetMe(options?: any): AxiosPromise<AdminDto> {
            return localVarFp.adminsControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} city 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerGetModeratorEmail(city: string, region: string, options?: any): AxiosPromise<Admin> {
            return localVarFp.adminsControllerGetModeratorEmail(city, region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign in via email/password
         * @param {AdminsSignInBodyDto} adminsSignInBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminsControllerSignIn(adminsSignInBodyDto: AdminsSignInBodyDto, options?: any): AxiosPromise<AuthAdminDto> {
            return localVarFp.adminsControllerSignIn(adminsSignInBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1AdminsApi - object-oriented interface
 * @export
 * @class V1AdminsApi
 * @extends {BaseAPI}
 */
export class V1AdminsApi extends BaseAPI {
    /**
     * 
     * @summary Check admin email for uniqueness
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminsApi
     */
    public adminsControllerEmailIsUniq(email: string, options?: any) {
        return V1AdminsApiFp(this.configuration).adminsControllerEmailIsUniq(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve current admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminsApi
     */
    public adminsControllerGetMe(options?: any) {
        return V1AdminsApiFp(this.configuration).adminsControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} city 
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminsApi
     */
    public adminsControllerGetModeratorEmail(city: string, region: string, options?: any) {
        return V1AdminsApiFp(this.configuration).adminsControllerGetModeratorEmail(city, region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign in via email/password
     * @param {AdminsSignInBodyDto} adminsSignInBodyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1AdminsApi
     */
    public adminsControllerSignIn(adminsSignInBodyDto: AdminsSignInBodyDto, options?: any) {
        return V1AdminsApiFp(this.configuration).adminsControllerSignIn(adminsSignInBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1BlockCustomersApi - axios parameter creator
 * @export
 */
export const V1BlockCustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Block customer
         * @param {string} blockedCustomerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCustomersControllerBlock: async (blockedCustomerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockedCustomerId' is not null or undefined
            assertParamExists('blockCustomersControllerBlock', 'blockedCustomerId', blockedCustomerId)
            const localVarPath = `/v1/block-customers/block/{blockedCustomerId}`
                .replace(`{${"blockedCustomerId"}}`, encodeURIComponent(String(blockedCustomerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get blocked customers
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCustomersControllerGetBlockedCustomers: async (afterCursor?: string, limit?: number, order?: OrderType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/block-customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (afterCursor !== undefined) {
                localVarQueryParameter['afterCursor'] = afterCursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unblock customer
         * @param {string} blockedCustomerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCustomersControllerUnblock: async (blockedCustomerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blockedCustomerId' is not null or undefined
            assertParamExists('blockCustomersControllerUnblock', 'blockedCustomerId', blockedCustomerId)
            const localVarPath = `/v1/block-customers/unblock/{blockedCustomerId}`
                .replace(`{${"blockedCustomerId"}}`, encodeURIComponent(String(blockedCustomerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1BlockCustomersApi - functional programming interface
 * @export
 */
export const V1BlockCustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1BlockCustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Block customer
         * @param {string} blockedCustomerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockCustomersControllerBlock(blockedCustomerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockCustomer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockCustomersControllerBlock(blockedCustomerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get blocked customers
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockCustomersControllerGetBlockedCustomers(afterCursor?: string, limit?: number, order?: OrderType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlockCustomersPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockCustomersControllerGetBlockedCustomers(afterCursor, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unblock customer
         * @param {string} blockedCustomerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockCustomersControllerUnblock(blockedCustomerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockCustomersControllerUnblock(blockedCustomerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1BlockCustomersApi - factory interface
 * @export
 */
export const V1BlockCustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1BlockCustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary Block customer
         * @param {string} blockedCustomerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCustomersControllerBlock(blockedCustomerId: string, options?: any): AxiosPromise<BlockCustomer> {
            return localVarFp.blockCustomersControllerBlock(blockedCustomerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get blocked customers
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCustomersControllerGetBlockedCustomers(afterCursor?: string, limit?: number, order?: OrderType, options?: any): AxiosPromise<BlockCustomersPaginatedDto> {
            return localVarFp.blockCustomersControllerGetBlockedCustomers(afterCursor, limit, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unblock customer
         * @param {string} blockedCustomerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockCustomersControllerUnblock(blockedCustomerId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.blockCustomersControllerUnblock(blockedCustomerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1BlockCustomersApi - object-oriented interface
 * @export
 * @class V1BlockCustomersApi
 * @extends {BaseAPI}
 */
export class V1BlockCustomersApi extends BaseAPI {
    /**
     * 
     * @summary Block customer
     * @param {string} blockedCustomerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1BlockCustomersApi
     */
    public blockCustomersControllerBlock(blockedCustomerId: string, options?: any) {
        return V1BlockCustomersApiFp(this.configuration).blockCustomersControllerBlock(blockedCustomerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get blocked customers
     * @param {string} [afterCursor] 
     * @param {number} [limit] 
     * @param {OrderType} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1BlockCustomersApi
     */
    public blockCustomersControllerGetBlockedCustomers(afterCursor?: string, limit?: number, order?: OrderType, options?: any) {
        return V1BlockCustomersApiFp(this.configuration).blockCustomersControllerGetBlockedCustomers(afterCursor, limit, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unblock customer
     * @param {string} blockedCustomerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1BlockCustomersApi
     */
    public blockCustomersControllerUnblock(blockedCustomerId: string, options?: any) {
        return V1BlockCustomersApiFp(this.configuration).blockCustomersControllerUnblock(blockedCustomerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CategoriesApi - axios parameter creator
 * @export
 */
export const V1CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetCategories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetCategoriesForDishes: async (type: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('categoriesControllerGetCategoriesForDishes', 'type', type)
            const localVarPath = `/v1/categories/get-by-type/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetCategoryById: async (categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('categoriesControllerGetCategoryById', 'categoryId', categoryId)
            const localVarPath = `/v1/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CategoriesApi - functional programming interface
 * @export
 */
export const V1CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerGetCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerGetCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerGetCategoriesForDishes(type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerGetCategoriesForDishes(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesControllerGetCategoryById(categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesControllerGetCategoryById(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CategoriesApi - factory interface
 * @export
 */
export const V1CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetCategories(options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.categoriesControllerGetCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetCategoriesForDishes(type: string, options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.categoriesControllerGetCategoriesForDishes(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesControllerGetCategoryById(categoryId: string, options?: any): AxiosPromise<Category> {
            return localVarFp.categoriesControllerGetCategoryById(categoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CategoriesApi - object-oriented interface
 * @export
 * @class V1CategoriesApi
 * @extends {BaseAPI}
 */
export class V1CategoriesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CategoriesApi
     */
    public categoriesControllerGetCategories(options?: any) {
        return V1CategoriesApiFp(this.configuration).categoriesControllerGetCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CategoriesApi
     */
    public categoriesControllerGetCategoriesForDishes(type: string, options?: any) {
        return V1CategoriesApiFp(this.configuration).categoriesControllerGetCategoriesForDishes(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CategoriesApi
     */
    public categoriesControllerGetCategoryById(categoryId: string, options?: any) {
        return V1CategoriesApiFp(this.configuration).categoriesControllerGetCategoryById(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ChatsApi - axios parameter creator
 * @export
 */
export const V1ChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerDeleteChat: async (chatId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('chatsControllerDeleteChat', 'chatId', chatId)
            const localVarPath = `/v1/chats/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetChatById: async (chatId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('chatsControllerGetChatById', 'chatId', chatId)
            const localVarPath = `/v1/chats/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chatId 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetChatMessages: async (chatId: string, afterCursor?: string, limit?: number, order?: OrderType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('chatsControllerGetChatMessages', 'chatId', chatId)
            const localVarPath = `/v1/chats/{chatId}/messages`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (afterCursor !== undefined) {
                localVarQueryParameter['afterCursor'] = afterCursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetChats: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/chats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetOrCreateChatWithSeller: async (offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('chatsControllerGetOrCreateChatWithSeller', 'offerId', offerId)
            const localVarPath = `/v1/chats/get-or-create/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all my unread messages in chat
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerReadMessages: async (chatId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('chatsControllerReadMessages', 'chatId', chatId)
            const localVarPath = `/v1/chats/{chatId}/messages/read`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} chatId 
         * @param {SendMessageDto} sendMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerSendMessage: async (chatId: string, sendMessageDto: SendMessageDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatId' is not null or undefined
            assertParamExists('chatsControllerSendMessage', 'chatId', chatId)
            // verify required parameter 'sendMessageDto' is not null or undefined
            assertParamExists('chatsControllerSendMessage', 'sendMessageDto', sendMessageDto)
            const localVarPath = `/v1/chats/send-message/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ChatsApi - functional programming interface
 * @export
 */
export const V1ChatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ChatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerDeleteChat(chatId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerDeleteChat(chatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerGetChatById(chatId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerGetChatById(chatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} chatId 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerGetChatMessages(chatId: string, afterCursor?: string, limit?: number, order?: OrderType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagesPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerGetChatMessages(chatId, afterCursor, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerGetChats(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Chat>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerGetChats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerGetOrCreateChatWithSeller(offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Chat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerGetOrCreateChatWithSeller(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all my unread messages in chat
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerReadMessages(chatId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerReadMessages(chatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} chatId 
         * @param {SendMessageDto} sendMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatsControllerSendMessage(chatId: string, sendMessageDto: SendMessageDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatsControllerSendMessage(chatId, sendMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ChatsApi - factory interface
 * @export
 */
export const V1ChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ChatsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerDeleteChat(chatId: string, options?: any): AxiosPromise<object> {
            return localVarFp.chatsControllerDeleteChat(chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetChatById(chatId: string, options?: any): AxiosPromise<Chat> {
            return localVarFp.chatsControllerGetChatById(chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} chatId 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetChatMessages(chatId: string, afterCursor?: string, limit?: number, order?: OrderType, options?: any): AxiosPromise<MessagesPaginatedDto> {
            return localVarFp.chatsControllerGetChatMessages(chatId, afterCursor, limit, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetChats(options?: any): AxiosPromise<Array<Chat>> {
            return localVarFp.chatsControllerGetChats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerGetOrCreateChatWithSeller(offerId: string, options?: any): AxiosPromise<Chat> {
            return localVarFp.chatsControllerGetOrCreateChatWithSeller(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all my unread messages in chat
         * @param {string} chatId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerReadMessages(chatId: string, options?: any): AxiosPromise<string> {
            return localVarFp.chatsControllerReadMessages(chatId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} chatId 
         * @param {SendMessageDto} sendMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatsControllerSendMessage(chatId: string, sendMessageDto: SendMessageDto, options?: any): AxiosPromise<Message> {
            return localVarFp.chatsControllerSendMessage(chatId, sendMessageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ChatsApi - object-oriented interface
 * @export
 * @class V1ChatsApi
 * @extends {BaseAPI}
 */
export class V1ChatsApi extends BaseAPI {
    /**
     * 
     * @param {string} chatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatsControllerDeleteChat(chatId: string, options?: any) {
        return V1ChatsApiFp(this.configuration).chatsControllerDeleteChat(chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} chatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatsControllerGetChatById(chatId: string, options?: any) {
        return V1ChatsApiFp(this.configuration).chatsControllerGetChatById(chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} chatId 
     * @param {string} [afterCursor] 
     * @param {number} [limit] 
     * @param {OrderType} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatsControllerGetChatMessages(chatId: string, afterCursor?: string, limit?: number, order?: OrderType, options?: any) {
        return V1ChatsApiFp(this.configuration).chatsControllerGetChatMessages(chatId, afterCursor, limit, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatsControllerGetChats(options?: any) {
        return V1ChatsApiFp(this.configuration).chatsControllerGetChats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatsControllerGetOrCreateChatWithSeller(offerId: string, options?: any) {
        return V1ChatsApiFp(this.configuration).chatsControllerGetOrCreateChatWithSeller(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all my unread messages in chat
     * @param {string} chatId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatsControllerReadMessages(chatId: string, options?: any) {
        return V1ChatsApiFp(this.configuration).chatsControllerReadMessages(chatId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} chatId 
     * @param {SendMessageDto} sendMessageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ChatsApi
     */
    public chatsControllerSendMessage(chatId: string, sendMessageDto: SendMessageDto, options?: any) {
        return V1ChatsApiFp(this.configuration).chatsControllerSendMessage(chatId, sendMessageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CitiesDistrictsApi - axios parameter creator
 * @export
 */
export const V1CitiesDistrictsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} city 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesDistrictsControllerFindDistrictsByCityAndRegion: async (city: string, region: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'city' is not null or undefined
            assertParamExists('citiesDistrictsControllerFindDistrictsByCityAndRegion', 'city', city)
            // verify required parameter 'region' is not null or undefined
            assertParamExists('citiesDistrictsControllerFindDistrictsByCityAndRegion', 'region', region)
            const localVarPath = `/v1/cities-districts/{city}/{region}`
                .replace(`{${"city"}}`, encodeURIComponent(String(city)))
                .replace(`{${"region"}}`, encodeURIComponent(String(region)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CitiesDistrictsApi - functional programming interface
 * @export
 */
export const V1CitiesDistrictsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CitiesDistrictsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} city 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async citiesDistrictsControllerFindDistrictsByCityAndRegion(city: string, region: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.citiesDistrictsControllerFindDistrictsByCityAndRegion(city, region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CitiesDistrictsApi - factory interface
 * @export
 */
export const V1CitiesDistrictsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CitiesDistrictsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} city 
         * @param {string} region 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesDistrictsControllerFindDistrictsByCityAndRegion(city: string, region: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.citiesDistrictsControllerFindDistrictsByCityAndRegion(city, region, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CitiesDistrictsApi - object-oriented interface
 * @export
 * @class V1CitiesDistrictsApi
 * @extends {BaseAPI}
 */
export class V1CitiesDistrictsApi extends BaseAPI {
    /**
     * 
     * @param {string} city 
     * @param {string} region 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CitiesDistrictsApi
     */
    public citiesDistrictsControllerFindDistrictsByCityAndRegion(city: string, region: string, options?: any) {
        return V1CitiesDistrictsApiFp(this.configuration).citiesDistrictsControllerFindDistrictsByCityAndRegion(city, region, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CustomerFavouriteOffersApi - axios parameter creator
 * @export
 */
export const V1CustomerFavouriteOffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteOffersControllerAddOfferToFavourites: async (offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('customerFavouriteOffersControllerAddOfferToFavourites', 'offerId', offerId)
            const localVarPath = `/v1/customer-favourite-offers/add-to-favourites/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteOffersControllerDeleteOfferFromFavourites: async (offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('customerFavouriteOffersControllerDeleteOfferFromFavourites', 'offerId', offerId)
            const localVarPath = `/v1/customer-favourite-offers/delete-from-favourites/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteOffersControllerGetCustomerFavouriteOffers: async (afterCursor?: string, limit?: number, order?: OrderType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/customer-favourite-offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (afterCursor !== undefined) {
                localVarQueryParameter['afterCursor'] = afterCursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CustomerFavouriteOffersApi - functional programming interface
 * @export
 */
export const V1CustomerFavouriteOffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CustomerFavouriteOffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerFavouriteOffersControllerAddOfferToFavourites(offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerFavouriteOffersControllerAddOfferToFavourites(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerFavouriteOffersControllerDeleteOfferFromFavourites(offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerFavouriteOffersControllerDeleteOfferFromFavourites(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerFavouriteOffersControllerGetCustomerFavouriteOffers(afterCursor?: string, limit?: number, order?: OrderType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerFavouriteOffersPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerFavouriteOffersControllerGetCustomerFavouriteOffers(afterCursor, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CustomerFavouriteOffersApi - factory interface
 * @export
 */
export const V1CustomerFavouriteOffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CustomerFavouriteOffersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteOffersControllerAddOfferToFavourites(offerId: string, options?: any): AxiosPromise<object> {
            return localVarFp.customerFavouriteOffersControllerAddOfferToFavourites(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteOffersControllerDeleteOfferFromFavourites(offerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerFavouriteOffersControllerDeleteOfferFromFavourites(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteOffersControllerGetCustomerFavouriteOffers(afterCursor?: string, limit?: number, order?: OrderType, options?: any): AxiosPromise<CustomerFavouriteOffersPaginatedDto> {
            return localVarFp.customerFavouriteOffersControllerGetCustomerFavouriteOffers(afterCursor, limit, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CustomerFavouriteOffersApi - object-oriented interface
 * @export
 * @class V1CustomerFavouriteOffersApi
 * @extends {BaseAPI}
 */
export class V1CustomerFavouriteOffersApi extends BaseAPI {
    /**
     * 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomerFavouriteOffersApi
     */
    public customerFavouriteOffersControllerAddOfferToFavourites(offerId: string, options?: any) {
        return V1CustomerFavouriteOffersApiFp(this.configuration).customerFavouriteOffersControllerAddOfferToFavourites(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomerFavouriteOffersApi
     */
    public customerFavouriteOffersControllerDeleteOfferFromFavourites(offerId: string, options?: any) {
        return V1CustomerFavouriteOffersApiFp(this.configuration).customerFavouriteOffersControllerDeleteOfferFromFavourites(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [afterCursor] 
     * @param {number} [limit] 
     * @param {OrderType} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomerFavouriteOffersApi
     */
    public customerFavouriteOffersControllerGetCustomerFavouriteOffers(afterCursor?: string, limit?: number, order?: OrderType, options?: any) {
        return V1CustomerFavouriteOffersApiFp(this.configuration).customerFavouriteOffersControllerGetCustomerFavouriteOffers(afterCursor, limit, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CustomerFavouriteSellersApi - axios parameter creator
 * @export
 */
export const V1CustomerFavouriteSellersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteSellersControllerAddSellerToFavourites: async (sellerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sellerId' is not null or undefined
            assertParamExists('customerFavouriteSellersControllerAddSellerToFavourites', 'sellerId', sellerId)
            const localVarPath = `/v1/customer-favourite-sellers/add-to-favourites/{sellerId}`
                .replace(`{${"sellerId"}}`, encodeURIComponent(String(sellerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteSellersControllerDeleteSellerFromFavourites: async (sellerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sellerId' is not null or undefined
            assertParamExists('customerFavouriteSellersControllerDeleteSellerFromFavourites', 'sellerId', sellerId)
            const localVarPath = `/v1/customer-favourite-sellers/delete-from-favourites/{sellerId}`
                .replace(`{${"sellerId"}}`, encodeURIComponent(String(sellerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteSellersControllerGetCustomerFavouriteSellers: async (afterCursor?: string, limit?: number, order?: OrderType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/customer-favourite-sellers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (afterCursor !== undefined) {
                localVarQueryParameter['afterCursor'] = afterCursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CustomerFavouriteSellersApi - functional programming interface
 * @export
 */
export const V1CustomerFavouriteSellersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CustomerFavouriteSellersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerFavouriteSellersControllerAddSellerToFavourites(sellerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerFavouriteSellersControllerAddSellerToFavourites(sellerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerFavouriteSellersControllerDeleteSellerFromFavourites(sellerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerFavouriteSellersControllerDeleteSellerFromFavourites(sellerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerFavouriteSellersControllerGetCustomerFavouriteSellers(afterCursor?: string, limit?: number, order?: OrderType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerFavouriteSellersPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerFavouriteSellersControllerGetCustomerFavouriteSellers(afterCursor, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CustomerFavouriteSellersApi - factory interface
 * @export
 */
export const V1CustomerFavouriteSellersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CustomerFavouriteSellersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteSellersControllerAddSellerToFavourites(sellerId: string, options?: any): AxiosPromise<object> {
            return localVarFp.customerFavouriteSellersControllerAddSellerToFavourites(sellerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sellerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteSellersControllerDeleteSellerFromFavourites(sellerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customerFavouriteSellersControllerDeleteSellerFromFavourites(sellerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerFavouriteSellersControllerGetCustomerFavouriteSellers(afterCursor?: string, limit?: number, order?: OrderType, options?: any): AxiosPromise<CustomerFavouriteSellersPaginatedDto> {
            return localVarFp.customerFavouriteSellersControllerGetCustomerFavouriteSellers(afterCursor, limit, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CustomerFavouriteSellersApi - object-oriented interface
 * @export
 * @class V1CustomerFavouriteSellersApi
 * @extends {BaseAPI}
 */
export class V1CustomerFavouriteSellersApi extends BaseAPI {
    /**
     * 
     * @param {string} sellerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomerFavouriteSellersApi
     */
    public customerFavouriteSellersControllerAddSellerToFavourites(sellerId: string, options?: any) {
        return V1CustomerFavouriteSellersApiFp(this.configuration).customerFavouriteSellersControllerAddSellerToFavourites(sellerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sellerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomerFavouriteSellersApi
     */
    public customerFavouriteSellersControllerDeleteSellerFromFavourites(sellerId: string, options?: any) {
        return V1CustomerFavouriteSellersApiFp(this.configuration).customerFavouriteSellersControllerDeleteSellerFromFavourites(sellerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [afterCursor] 
     * @param {number} [limit] 
     * @param {OrderType} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomerFavouriteSellersApi
     */
    public customerFavouriteSellersControllerGetCustomerFavouriteSellers(afterCursor?: string, limit?: number, order?: OrderType, options?: any) {
        return V1CustomerFavouriteSellersApiFp(this.configuration).customerFavouriteSellersControllerGetCustomerFavouriteSellers(afterCursor, limit, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CustomerNotificationsApi - axios parameter creator
 * @export
 */
export const V1CustomerNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerNotificationsControllerGetNotifications: async (afterCursor?: string, limit?: number, order?: OrderType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/customer-notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (afterCursor !== undefined) {
                localVarQueryParameter['afterCursor'] = afterCursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CustomerNotificationsApi - functional programming interface
 * @export
 */
export const V1CustomerNotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CustomerNotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerNotificationsControllerGetNotifications(afterCursor?: string, limit?: number, order?: OrderType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerNotificationsPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerNotificationsControllerGetNotifications(afterCursor, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CustomerNotificationsApi - factory interface
 * @export
 */
export const V1CustomerNotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CustomerNotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerNotificationsControllerGetNotifications(afterCursor?: string, limit?: number, order?: OrderType, options?: any): AxiosPromise<CustomerNotificationsPaginatedDto> {
            return localVarFp.customerNotificationsControllerGetNotifications(afterCursor, limit, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CustomerNotificationsApi - object-oriented interface
 * @export
 * @class V1CustomerNotificationsApi
 * @extends {BaseAPI}
 */
export class V1CustomerNotificationsApi extends BaseAPI {
    /**
     * 
     * @param {string} [afterCursor] 
     * @param {number} [limit] 
     * @param {OrderType} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomerNotificationsApi
     */
    public customerNotificationsControllerGetNotifications(afterCursor?: string, limit?: number, order?: OrderType, options?: any) {
        return V1CustomerNotificationsApiFp(this.configuration).customerNotificationsControllerGetNotifications(afterCursor, limit, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CustomerReportsApi - axios parameter creator
 * @export
 */
export const V1CustomerReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {SendReportDto} sendReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReportsControllerReportCustomer: async (customerId: string, sendReportDto: SendReportDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerReportsControllerReportCustomer', 'customerId', customerId)
            // verify required parameter 'sendReportDto' is not null or undefined
            assertParamExists('customerReportsControllerReportCustomer', 'sendReportDto', sendReportDto)
            const localVarPath = `/v1/customer-reports/report/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CustomerReportsApi - functional programming interface
 * @export
 */
export const V1CustomerReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CustomerReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {SendReportDto} sendReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerReportsControllerReportCustomer(customerId: string, sendReportDto: SendReportDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerReportsControllerReportCustomer(customerId, sendReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CustomerReportsApi - factory interface
 * @export
 */
export const V1CustomerReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CustomerReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {SendReportDto} sendReportDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerReportsControllerReportCustomer(customerId: string, sendReportDto: SendReportDto, options?: any): AxiosPromise<CustomerReport> {
            return localVarFp.customerReportsControllerReportCustomer(customerId, sendReportDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CustomerReportsApi - object-oriented interface
 * @export
 * @class V1CustomerReportsApi
 * @extends {BaseAPI}
 */
export class V1CustomerReportsApi extends BaseAPI {
    /**
     * 
     * @param {string} customerId 
     * @param {SendReportDto} sendReportDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomerReportsApi
     */
    public customerReportsControllerReportCustomer(customerId: string, sendReportDto: SendReportDto, options?: any) {
        return V1CustomerReportsApiFp(this.configuration).customerReportsControllerReportCustomer(customerId, sendReportDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1CustomersApi - axios parameter creator
 * @export
 */
export const V1CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sign-in with apple and retrieve user and bearer token
         * @param {AppleSignInDto} appleSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerAppleSignIn: async (appleSignInDto: AppleSignInDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appleSignInDto' is not null or undefined
            assertParamExists('customersControllerAppleSignIn', 'appleSignInDto', appleSignInDto)
            const localVarPath = `/v1/customers/apple/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appleSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change customer\'s phone number
         * @param {ChangePhoneDto} changePhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerChangePhone: async (changePhoneDto: ChangePhoneDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePhoneDto' is not null or undefined
            assertParamExists('customersControllerChangePhone', 'changePhoneDto', changePhoneDto)
            const localVarPath = `/v1/customers/phone/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePhoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create signed url to upload avatar
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerCreateSignedUrlForAvatar: async (signedUrlBodyDto: SignedUrlBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signedUrlBodyDto' is not null or undefined
            assertParamExists('customersControllerCreateSignedUrlForAvatar', 'signedUrlBodyDto', signedUrlBodyDto)
            const localVarPath = `/v1/customers/avatar-signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedUrlBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete customer firebase token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteFcmToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/customers/fcm-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteOffer: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/customers/delete-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetCustomerById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customersControllerGetCustomerById', 'id', id)
            const localVarPath = `/v1/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/customers/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign-in with google and retrieve user and bearer token
         * @param {GoogleSignInDto} googleSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGoogleSignIn: async (googleSignInDto: GoogleSignInDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleSignInDto' is not null or undefined
            assertParamExists('customersControllerGoogleSignIn', 'googleSignInDto', googleSignInDto)
            const localVarPath = `/v1/customers/google/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current user profile
         * @param {PatchCustomerProfileDto} patchCustomerProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerPathProfile: async (patchCustomerProfileDto: PatchCustomerProfileDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchCustomerProfileDto' is not null or undefined
            assertParamExists('customersControllerPathProfile', 'patchCustomerProfileDto', patchCustomerProfileDto)
            const localVarPath = `/v1/customers/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCustomerProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check phone for uniqueness
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerPhoneIsUniq: async (phone: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('customersControllerPhoneIsUniq', 'phone', phone)
            const localVarPath = `/v1/customers/phone-is-uniq`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send code for sign-in with phone or for change phone
         * @param {SmsCodeDto} smsCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerSendSmsCodeForSignin: async (smsCodeDto: SmsCodeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsCodeDto' is not null or undefined
            assertParamExists('customersControllerSendSmsCodeForSignin', 'smsCodeDto', smsCodeDto)
            const localVarPath = `/v1/customers/phone/code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smsCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign-in with phone retrive user with bearer token
         * @param {SigninDto} signinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerSignIn: async (signinDto: SigninDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signinDto' is not null or undefined
            assertParamExists('customersControllerSignIn', 'signinDto', signinDto)
            const localVarPath = `/v1/customers/phone/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signinDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer firebase token
         * @param {PatchCustomerFcmDto} patchCustomerFcmDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdateFcmToken: async (patchCustomerFcmDto: PatchCustomerFcmDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchCustomerFcmDto' is not null or undefined
            assertParamExists('customersControllerUpdateFcmToken', 'patchCustomerFcmDto', patchCustomerFcmDto)
            const localVarPath = `/v1/customers/fcm-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCustomerFcmDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer notifications settings
         * @param {PatchNotificationsSettingsDto} patchNotificationsSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdateNotificationsSettings: async (patchNotificationsSettingsDto: PatchNotificationsSettingsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchNotificationsSettingsDto' is not null or undefined
            assertParamExists('customersControllerUpdateNotificationsSettings', 'patchNotificationsSettingsDto', patchNotificationsSettingsDto)
            const localVarPath = `/v1/customers/notifications-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchNotificationsSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign-in with vk and retrieve user and bearer token
         * @param {VkSignInDto} vkSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerVkSignIn: async (vkSignInDto: VkSignInDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vkSignInDto' is not null or undefined
            assertParamExists('customersControllerVkSignIn', 'vkSignInDto', vkSignInDto)
            const localVarPath = `/v1/customers/vk/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vkSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1CustomersApi - functional programming interface
 * @export
 */
export const V1CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sign-in with apple and retrieve user and bearer token
         * @param {AppleSignInDto} appleSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerAppleSignIn(appleSignInDto: AppleSignInDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerAppleSignIn(appleSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change customer\'s phone number
         * @param {ChangePhoneDto} changePhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerChangePhone(changePhoneDto: ChangePhoneDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerChangePhone(changePhoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create signed url to upload avatar
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerCreateSignedUrlForAvatar(signedUrlBodyDto: SignedUrlBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerCreateSignedUrlForAvatar(signedUrlBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete customer firebase token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerDeleteFcmToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerDeleteFcmToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerDeleteOffer(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerDeleteOffer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerGetCustomerById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerGetCustomerById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve current customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerGetMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign-in with google and retrieve user and bearer token
         * @param {GoogleSignInDto} googleSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerGoogleSignIn(googleSignInDto: GoogleSignInDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerGoogleSignIn(googleSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current user profile
         * @param {PatchCustomerProfileDto} patchCustomerProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerPathProfile(patchCustomerProfileDto: PatchCustomerProfileDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerPathProfile(patchCustomerProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check phone for uniqueness
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerPhoneIsUniq(phone: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerPhoneIsUniq(phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send code for sign-in with phone or for change phone
         * @param {SmsCodeDto} smsCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerSendSmsCodeForSignin(smsCodeDto: SmsCodeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerSendSmsCodeForSignin(smsCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign-in with phone retrive user with bearer token
         * @param {SigninDto} signinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerSignIn(signinDto: SigninDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerSignIn(signinDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer firebase token
         * @param {PatchCustomerFcmDto} patchCustomerFcmDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUpdateFcmToken(patchCustomerFcmDto: PatchCustomerFcmDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUpdateFcmToken(patchCustomerFcmDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update customer notifications settings
         * @param {PatchNotificationsSettingsDto} patchNotificationsSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUpdateNotificationsSettings(patchNotificationsSettingsDto: PatchNotificationsSettingsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUpdateNotificationsSettings(patchNotificationsSettingsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign-in with vk and retrieve user and bearer token
         * @param {VkSignInDto} vkSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerVkSignIn(vkSignInDto: VkSignInDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthCustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerVkSignIn(vkSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1CustomersApi - factory interface
 * @export
 */
export const V1CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1CustomersApiFp(configuration)
    return {
        /**
         * 
         * @summary Sign-in with apple and retrieve user and bearer token
         * @param {AppleSignInDto} appleSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerAppleSignIn(appleSignInDto: AppleSignInDto, options?: any): AxiosPromise<AuthCustomerDto> {
            return localVarFp.customersControllerAppleSignIn(appleSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change customer\'s phone number
         * @param {ChangePhoneDto} changePhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerChangePhone(changePhoneDto: ChangePhoneDto, options?: any): AxiosPromise<Customer> {
            return localVarFp.customersControllerChangePhone(changePhoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create signed url to upload avatar
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerCreateSignedUrlForAvatar(signedUrlBodyDto: SignedUrlBodyDto, options?: any): AxiosPromise<SignedUrlResDto> {
            return localVarFp.customersControllerCreateSignedUrlForAvatar(signedUrlBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete customer firebase token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteFcmToken(options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerDeleteFcmToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteOffer(options?: any): AxiosPromise<boolean> {
            return localVarFp.customersControllerDeleteOffer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetCustomerById(id: string, options?: any): AxiosPromise<Customer> {
            return localVarFp.customersControllerGetCustomerById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve current customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetMe(options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign-in with google and retrieve user and bearer token
         * @param {GoogleSignInDto} googleSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGoogleSignIn(googleSignInDto: GoogleSignInDto, options?: any): AxiosPromise<AuthCustomerDto> {
            return localVarFp.customersControllerGoogleSignIn(googleSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current user profile
         * @param {PatchCustomerProfileDto} patchCustomerProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerPathProfile(patchCustomerProfileDto: PatchCustomerProfileDto, options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerPathProfile(patchCustomerProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check phone for uniqueness
         * @param {string} phone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerPhoneIsUniq(phone: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.customersControllerPhoneIsUniq(phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send code for sign-in with phone or for change phone
         * @param {SmsCodeDto} smsCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerSendSmsCodeForSignin(smsCodeDto: SmsCodeDto, options?: any): AxiosPromise<string> {
            return localVarFp.customersControllerSendSmsCodeForSignin(smsCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign-in with phone retrive user with bearer token
         * @param {SigninDto} signinDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerSignIn(signinDto: SigninDto, options?: any): AxiosPromise<AuthCustomerDto> {
            return localVarFp.customersControllerSignIn(signinDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer firebase token
         * @param {PatchCustomerFcmDto} patchCustomerFcmDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdateFcmToken(patchCustomerFcmDto: PatchCustomerFcmDto, options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerUpdateFcmToken(patchCustomerFcmDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer notifications settings
         * @param {PatchNotificationsSettingsDto} patchNotificationsSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdateNotificationsSettings(patchNotificationsSettingsDto: PatchNotificationsSettingsDto, options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerUpdateNotificationsSettings(patchNotificationsSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign-in with vk and retrieve user and bearer token
         * @param {VkSignInDto} vkSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerVkSignIn(vkSignInDto: VkSignInDto, options?: any): AxiosPromise<AuthCustomerDto> {
            return localVarFp.customersControllerVkSignIn(vkSignInDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1CustomersApi - object-oriented interface
 * @export
 * @class V1CustomersApi
 * @extends {BaseAPI}
 */
export class V1CustomersApi extends BaseAPI {
    /**
     * 
     * @summary Sign-in with apple and retrieve user and bearer token
     * @param {AppleSignInDto} appleSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerAppleSignIn(appleSignInDto: AppleSignInDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerAppleSignIn(appleSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change customer\'s phone number
     * @param {ChangePhoneDto} changePhoneDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerChangePhone(changePhoneDto: ChangePhoneDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerChangePhone(changePhoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create signed url to upload avatar
     * @param {SignedUrlBodyDto} signedUrlBodyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerCreateSignedUrlForAvatar(signedUrlBodyDto: SignedUrlBodyDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerCreateSignedUrlForAvatar(signedUrlBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete customer firebase token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerDeleteFcmToken(options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerDeleteFcmToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerDeleteOffer(options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerDeleteOffer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerGetCustomerById(id: string, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerGetCustomerById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve current customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerGetMe(options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign-in with google and retrieve user and bearer token
     * @param {GoogleSignInDto} googleSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerGoogleSignIn(googleSignInDto: GoogleSignInDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerGoogleSignIn(googleSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current user profile
     * @param {PatchCustomerProfileDto} patchCustomerProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerPathProfile(patchCustomerProfileDto: PatchCustomerProfileDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerPathProfile(patchCustomerProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check phone for uniqueness
     * @param {string} phone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerPhoneIsUniq(phone: string, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerPhoneIsUniq(phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send code for sign-in with phone or for change phone
     * @param {SmsCodeDto} smsCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerSendSmsCodeForSignin(smsCodeDto: SmsCodeDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerSendSmsCodeForSignin(smsCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign-in with phone retrive user with bearer token
     * @param {SigninDto} signinDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerSignIn(signinDto: SigninDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerSignIn(signinDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer firebase token
     * @param {PatchCustomerFcmDto} patchCustomerFcmDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerUpdateFcmToken(patchCustomerFcmDto: PatchCustomerFcmDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerUpdateFcmToken(patchCustomerFcmDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer notifications settings
     * @param {PatchNotificationsSettingsDto} patchNotificationsSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerUpdateNotificationsSettings(patchNotificationsSettingsDto: PatchNotificationsSettingsDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerUpdateNotificationsSettings(patchNotificationsSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign-in with vk and retrieve user and bearer token
     * @param {VkSignInDto} vkSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1CustomersApi
     */
    public customersControllerVkSignIn(vkSignInDto: VkSignInDto, options?: any) {
        return V1CustomersApiFp(this.configuration).customersControllerVkSignIn(vkSignInDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1ManagerProfileApi - axios parameter creator
 * @export
 */
export const V1ManagerProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve current admin id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerGetId: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/get-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerGetMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update current admin
         * @param {any} managerId 
         * @param {ManagerUpdateDto} managerUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerUpdateMyProfile: async (managerId: any, managerUpdateDto: ManagerUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'managerId' is not null or undefined
            assertParamExists('adminControllerUpdateMyProfile', 'managerId', managerId)
            // verify required parameter 'managerUpdateDto' is not null or undefined
            assertParamExists('adminControllerUpdateMyProfile', 'managerUpdateDto', managerUpdateDto)
            const localVarPath = `/v1/admin/me`
                .replace(`{${"managerId"}}`, encodeURIComponent(String(managerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managerUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1ManagerProfileApi - functional programming interface
 * @export
 */
export const V1ManagerProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ManagerProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve current admin id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminControllerGetId(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerGetId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminControllerGetMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update current admin
         * @param {any} managerId 
         * @param {ManagerUpdateDto} managerUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminControllerUpdateMyProfile(managerId: any, managerUpdateDto: ManagerUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerUpdateMyProfile(managerId, managerUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1ManagerProfileApi - factory interface
 * @export
 */
export const V1ManagerProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ManagerProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve current admin id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerGetId(options?: any): AxiosPromise<AdminDto> {
            return localVarFp.adminControllerGetId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve current admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerGetMe(options?: any): AxiosPromise<AdminDto> {
            return localVarFp.adminControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update current admin
         * @param {any} managerId 
         * @param {ManagerUpdateDto} managerUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerUpdateMyProfile(managerId: any, managerUpdateDto: ManagerUpdateDto, options?: any): AxiosPromise<object> {
            return localVarFp.adminControllerUpdateMyProfile(managerId, managerUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1ManagerProfileApi - object-oriented interface
 * @export
 * @class V1ManagerProfileApi
 * @extends {BaseAPI}
 */
export class V1ManagerProfileApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve current admin id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ManagerProfileApi
     */
    public adminControllerGetId(options?: any) {
        return V1ManagerProfileApiFp(this.configuration).adminControllerGetId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve current admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ManagerProfileApi
     */
    public adminControllerGetMe(options?: any) {
        return V1ManagerProfileApiFp(this.configuration).adminControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update current admin
     * @param {any} managerId 
     * @param {ManagerUpdateDto} managerUpdateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1ManagerProfileApi
     */
    public adminControllerUpdateMyProfile(managerId: any, managerUpdateDto: ManagerUpdateDto, options?: any) {
        return V1ManagerProfileApiFp(this.configuration).adminControllerUpdateMyProfile(managerId, managerUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1OffersApi - axios parameter creator
 * @export
 */
export const V1OffersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerCloseOffer: async (offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offersControllerCloseOffer', 'offerId', offerId)
            const localVarPath = `/v1/offers/close-offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} rateId 
         * @param {CreateOfferDto} createOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerCreateOffer: async (rateId: string, createOfferDto: CreateOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rateId' is not null or undefined
            assertParamExists('offersControllerCreateOffer', 'rateId', rateId)
            // verify required parameter 'createOfferDto' is not null or undefined
            assertParamExists('offersControllerCreateOffer', 'createOfferDto', createOfferDto)
            const localVarPath = `/v1/offers/create-offer/{rateId}`
                .replace(`{${"rateId"}}`, encodeURIComponent(String(rateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOfferWithoutRateDto} createOfferWithoutRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerCreateOfferWithoutRate: async (createOfferWithoutRateDto: CreateOfferWithoutRateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfferWithoutRateDto' is not null or undefined
            assertParamExists('offersControllerCreateOfferWithoutRate', 'createOfferWithoutRateDto', createOfferWithoutRateDto)
            const localVarPath = `/v1/offers/create-offer-without-rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferWithoutRateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create signed url to upload offer photo
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerCreateSignedUrlForPhoto: async (signedUrlBodyDto: SignedUrlBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signedUrlBodyDto' is not null or undefined
            assertParamExists('offersControllerCreateSignedUrlForPhoto', 'signedUrlBodyDto', signedUrlBodyDto)
            const localVarPath = `/v1/offers/create-offer-photo-signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signedUrlBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerDeleteOffer: async (offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offersControllerDeleteOffer', 'offerId', offerId)
            const localVarPath = `/v1/offers/delete-offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {OfferStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerGetMyOffers: async (afterCursor?: string, limit?: number, order?: OrderType, status?: OfferStatus, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/offers/get-my-offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (afterCursor !== undefined) {
                localVarQueryParameter['afterCursor'] = afterCursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerGetOfferById: async (offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offersControllerGetOfferById', 'offerId', offerId)
            const localVarPath = `/v1/offers/get-offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {string} [title] 
         * @param {string} [categoryId] 
         * @param {string} [city] 
         * @param {string} [district] 
         * @param {string} [region] 
         * @param {number} [priceFrom] 
         * @param {number} [priceTo] 
         * @param {OfferStatus} [status] 
         * @param {string} [customerId] 
         * @param {OfferType} [type] 
         * @param {boolean} [isWish] 
         * @param {OffersSortType} [sortBy] 
         * @param {boolean} [sortByDistance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerGetOffers: async (afterCursor?: string, limit?: number, order?: OrderType, title?: string, categoryId?: string, city?: string, district?: string, region?: string, priceFrom?: number, priceTo?: number, status?: OfferStatus, customerId?: string, type?: OfferType, isWish?: boolean, sortBy?: OffersSortType, sortByDistance?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/offers/get-offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (afterCursor !== undefined) {
                localVarQueryParameter['afterCursor'] = afterCursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (district !== undefined) {
                localVarQueryParameter['district'] = district;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            if (priceFrom !== undefined) {
                localVarQueryParameter['priceFrom'] = priceFrom;
            }

            if (priceTo !== undefined) {
                localVarQueryParameter['priceTo'] = priceTo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (isWish !== undefined) {
                localVarQueryParameter['isWish'] = isWish;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortByDistance !== undefined) {
                localVarQueryParameter['sortByDistance'] = sortByDistance;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerOpenOffer: async (offerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offersControllerOpenOffer', 'offerId', offerId)
            const localVarPath = `/v1/offers/open-offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reopen expired offer
         * @param {string} offerId 
         * @param {ReopenOfferDto} reopenOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerReopenOffer: async (offerId: string, reopenOfferDto: ReopenOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offersControllerReopenOffer', 'offerId', offerId)
            // verify required parameter 'reopenOfferDto' is not null or undefined
            assertParamExists('offersControllerReopenOffer', 'reopenOfferDto', reopenOfferDto)
            const localVarPath = `/v1/offers/reopen-offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reopenOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reopen expired offer by rate
         * @param {string} offerId 
         * @param {ReopenOfferByRateDto} reopenOfferByRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerReopenOfferByRate: async (offerId: string, reopenOfferByRateDto: ReopenOfferByRateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offersControllerReopenOfferByRate', 'offerId', offerId)
            // verify required parameter 'reopenOfferByRateDto' is not null or undefined
            assertParamExists('offersControllerReopenOfferByRate', 'reopenOfferByRateDto', reopenOfferByRateDto)
            const localVarPath = `/v1/offers/reopen-offer-by-rate/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reopenOfferByRateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} offerId 
         * @param {UpdateOfferDto} updateOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerUpdateOffer: async (offerId: string, updateOfferDto: UpdateOfferDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('offersControllerUpdateOffer', 'offerId', offerId)
            // verify required parameter 'updateOfferDto' is not null or undefined
            assertParamExists('offersControllerUpdateOffer', 'updateOfferDto', updateOfferDto)
            const localVarPath = `/v1/offers/update-offer/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOfferDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1OffersApi - functional programming interface
 * @export
 */
export const V1OffersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1OffersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerCloseOffer(offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerCloseOffer(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} rateId 
         * @param {CreateOfferDto} createOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerCreateOffer(rateId: string, createOfferDto: CreateOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerCreateOffer(rateId, createOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOfferWithoutRateDto} createOfferWithoutRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerCreateOfferWithoutRate(createOfferWithoutRateDto: CreateOfferWithoutRateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerCreateOfferWithoutRate(createOfferWithoutRateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create signed url to upload offer photo
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerCreateSignedUrlForPhoto(signedUrlBodyDto: SignedUrlBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerCreateSignedUrlForPhoto(signedUrlBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerDeleteOffer(offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerDeleteOffer(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {OfferStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerGetMyOffers(afterCursor?: string, limit?: number, order?: OrderType, status?: OfferStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffersPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerGetMyOffers(afterCursor, limit, order, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerGetOfferById(offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerGetOfferById(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {string} [title] 
         * @param {string} [categoryId] 
         * @param {string} [city] 
         * @param {string} [district] 
         * @param {string} [region] 
         * @param {number} [priceFrom] 
         * @param {number} [priceTo] 
         * @param {OfferStatus} [status] 
         * @param {string} [customerId] 
         * @param {OfferType} [type] 
         * @param {boolean} [isWish] 
         * @param {OffersSortType} [sortBy] 
         * @param {boolean} [sortByDistance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerGetOffers(afterCursor?: string, limit?: number, order?: OrderType, title?: string, categoryId?: string, city?: string, district?: string, region?: string, priceFrom?: number, priceTo?: number, status?: OfferStatus, customerId?: string, type?: OfferType, isWish?: boolean, sortBy?: OffersSortType, sortByDistance?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OffersPaginatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerGetOffers(afterCursor, limit, order, title, categoryId, city, district, region, priceFrom, priceTo, status, customerId, type, isWish, sortBy, sortByDistance, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerOpenOffer(offerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerOpenOffer(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reopen expired offer
         * @param {string} offerId 
         * @param {ReopenOfferDto} reopenOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerReopenOffer(offerId: string, reopenOfferDto: ReopenOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerReopenOffer(offerId, reopenOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reopen expired offer by rate
         * @param {string} offerId 
         * @param {ReopenOfferByRateDto} reopenOfferByRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerReopenOfferByRate(offerId: string, reopenOfferByRateDto: ReopenOfferByRateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerReopenOfferByRate(offerId, reopenOfferByRateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} offerId 
         * @param {UpdateOfferDto} updateOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async offersControllerUpdateOffer(offerId: string, updateOfferDto: UpdateOfferDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.offersControllerUpdateOffer(offerId, updateOfferDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1OffersApi - factory interface
 * @export
 */
export const V1OffersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1OffersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerCloseOffer(offerId: string, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersControllerCloseOffer(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} rateId 
         * @param {CreateOfferDto} createOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerCreateOffer(rateId: string, createOfferDto: CreateOfferDto, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersControllerCreateOffer(rateId, createOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOfferWithoutRateDto} createOfferWithoutRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerCreateOfferWithoutRate(createOfferWithoutRateDto: CreateOfferWithoutRateDto, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersControllerCreateOfferWithoutRate(createOfferWithoutRateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create signed url to upload offer photo
         * @param {SignedUrlBodyDto} signedUrlBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerCreateSignedUrlForPhoto(signedUrlBodyDto: SignedUrlBodyDto, options?: any): AxiosPromise<SignedUrlResDto> {
            return localVarFp.offersControllerCreateSignedUrlForPhoto(signedUrlBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerDeleteOffer(offerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.offersControllerDeleteOffer(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {OfferStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerGetMyOffers(afterCursor?: string, limit?: number, order?: OrderType, status?: OfferStatus, options?: any): AxiosPromise<OffersPaginatedDto> {
            return localVarFp.offersControllerGetMyOffers(afterCursor, limit, order, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerGetOfferById(offerId: string, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersControllerGetOfferById(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [afterCursor] 
         * @param {number} [limit] 
         * @param {OrderType} [order] 
         * @param {string} [title] 
         * @param {string} [categoryId] 
         * @param {string} [city] 
         * @param {string} [district] 
         * @param {string} [region] 
         * @param {number} [priceFrom] 
         * @param {number} [priceTo] 
         * @param {OfferStatus} [status] 
         * @param {string} [customerId] 
         * @param {OfferType} [type] 
         * @param {boolean} [isWish] 
         * @param {OffersSortType} [sortBy] 
         * @param {boolean} [sortByDistance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerGetOffers(afterCursor?: string, limit?: number, order?: OrderType, title?: string, categoryId?: string, city?: string, district?: string, region?: string, priceFrom?: number, priceTo?: number, status?: OfferStatus, customerId?: string, type?: OfferType, isWish?: boolean, sortBy?: OffersSortType, sortByDistance?: boolean, options?: any): AxiosPromise<OffersPaginatedDto> {
            return localVarFp.offersControllerGetOffers(afterCursor, limit, order, title, categoryId, city, district, region, priceFrom, priceTo, status, customerId, type, isWish, sortBy, sortByDistance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerOpenOffer(offerId: string, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersControllerOpenOffer(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reopen expired offer
         * @param {string} offerId 
         * @param {ReopenOfferDto} reopenOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerReopenOffer(offerId: string, reopenOfferDto: ReopenOfferDto, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersControllerReopenOffer(offerId, reopenOfferDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reopen expired offer by rate
         * @param {string} offerId 
         * @param {ReopenOfferByRateDto} reopenOfferByRateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerReopenOfferByRate(offerId: string, reopenOfferByRateDto: ReopenOfferByRateDto, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersControllerReopenOfferByRate(offerId, reopenOfferByRateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} offerId 
         * @param {UpdateOfferDto} updateOfferDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        offersControllerUpdateOffer(offerId: string, updateOfferDto: UpdateOfferDto, options?: any): AxiosPromise<Offer> {
            return localVarFp.offersControllerUpdateOffer(offerId, updateOfferDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1OffersApi - object-oriented interface
 * @export
 * @class V1OffersApi
 * @extends {BaseAPI}
 */
export class V1OffersApi extends BaseAPI {
    /**
     * 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerCloseOffer(offerId: string, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerCloseOffer(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} rateId 
     * @param {CreateOfferDto} createOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerCreateOffer(rateId: string, createOfferDto: CreateOfferDto, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerCreateOffer(rateId, createOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOfferWithoutRateDto} createOfferWithoutRateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerCreateOfferWithoutRate(createOfferWithoutRateDto: CreateOfferWithoutRateDto, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerCreateOfferWithoutRate(createOfferWithoutRateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create signed url to upload offer photo
     * @param {SignedUrlBodyDto} signedUrlBodyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerCreateSignedUrlForPhoto(signedUrlBodyDto: SignedUrlBodyDto, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerCreateSignedUrlForPhoto(signedUrlBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerDeleteOffer(offerId: string, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerDeleteOffer(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [afterCursor] 
     * @param {number} [limit] 
     * @param {OrderType} [order] 
     * @param {OfferStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerGetMyOffers(afterCursor?: string, limit?: number, order?: OrderType, status?: OfferStatus, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerGetMyOffers(afterCursor, limit, order, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerGetOfferById(offerId: string, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerGetOfferById(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [afterCursor] 
     * @param {number} [limit] 
     * @param {OrderType} [order] 
     * @param {string} [title] 
     * @param {string} [categoryId] 
     * @param {string} [city] 
     * @param {string} [district] 
     * @param {string} [region] 
     * @param {number} [priceFrom] 
     * @param {number} [priceTo] 
     * @param {OfferStatus} [status] 
     * @param {string} [customerId] 
     * @param {OfferType} [type] 
     * @param {boolean} [isWish] 
     * @param {OffersSortType} [sortBy] 
     * @param {boolean} [sortByDistance] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerGetOffers(afterCursor?: string, limit?: number, order?: OrderType, title?: string, categoryId?: string, city?: string, district?: string, region?: string, priceFrom?: number, priceTo?: number, status?: OfferStatus, customerId?: string, type?: OfferType, isWish?: boolean, sortBy?: OffersSortType, sortByDistance?: boolean, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerGetOffers(afterCursor, limit, order, title, categoryId, city, district, region, priceFrom, priceTo, status, customerId, type, isWish, sortBy, sortByDistance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerOpenOffer(offerId: string, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerOpenOffer(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reopen expired offer
     * @param {string} offerId 
     * @param {ReopenOfferDto} reopenOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerReopenOffer(offerId: string, reopenOfferDto: ReopenOfferDto, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerReopenOffer(offerId, reopenOfferDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reopen expired offer by rate
     * @param {string} offerId 
     * @param {ReopenOfferByRateDto} reopenOfferByRateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerReopenOfferByRate(offerId: string, reopenOfferByRateDto: ReopenOfferByRateDto, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerReopenOfferByRate(offerId, reopenOfferByRateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} offerId 
     * @param {UpdateOfferDto} updateOfferDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1OffersApi
     */
    public offersControllerUpdateOffer(offerId: string, updateOfferDto: UpdateOfferDto, options?: any) {
        return V1OffersApiFp(this.configuration).offersControllerUpdateOffer(offerId, updateOfferDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1PromotionsApi - axios parameter creator
 * @export
 */
export const V1PromotionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [city] 
         * @param {string} [region] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPromotion: async (city?: string, region?: string, skip?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/promotions/get-promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SetViewedQueryDto} setViewedQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerSetAsViewed: async (id: string, setViewedQueryDto: SetViewedQueryDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('promotionsControllerSetAsViewed', 'id', id)
            // verify required parameter 'setViewedQueryDto' is not null or undefined
            assertParamExists('promotionsControllerSetAsViewed', 'setViewedQueryDto', setViewedQueryDto)
            const localVarPath = `/v1/promotions/set-as-viewed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setViewedQueryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1PromotionsApi - functional programming interface
 * @export
 */
export const V1PromotionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1PromotionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [city] 
         * @param {string} [region] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerGetPromotion(city?: string, region?: string, skip?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Promotion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerGetPromotion(city, region, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SetViewedQueryDto} setViewedQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionsControllerSetAsViewed(id: string, setViewedQueryDto: SetViewedQueryDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionsControllerSetAsViewed(id, setViewedQueryDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1PromotionsApi - factory interface
 * @export
 */
export const V1PromotionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1PromotionsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [city] 
         * @param {string} [region] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerGetPromotion(city?: string, region?: string, skip?: number, options?: any): AxiosPromise<Promotion> {
            return localVarFp.promotionsControllerGetPromotion(city, region, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SetViewedQueryDto} setViewedQueryDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionsControllerSetAsViewed(id: string, setViewedQueryDto: SetViewedQueryDto, options?: any): AxiosPromise<object> {
            return localVarFp.promotionsControllerSetAsViewed(id, setViewedQueryDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1PromotionsApi - object-oriented interface
 * @export
 * @class V1PromotionsApi
 * @extends {BaseAPI}
 */
export class V1PromotionsApi extends BaseAPI {
    /**
     * 
     * @param {string} [city] 
     * @param {string} [region] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PromotionsApi
     */
    public promotionsControllerGetPromotion(city?: string, region?: string, skip?: number, options?: any) {
        return V1PromotionsApiFp(this.configuration).promotionsControllerGetPromotion(city, region, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SetViewedQueryDto} setViewedQueryDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PromotionsApi
     */
    public promotionsControllerSetAsViewed(id: string, setViewedQueryDto: SetViewedQueryDto, options?: any) {
        return V1PromotionsApiFp(this.configuration).promotionsControllerSetAsViewed(id, setViewedQueryDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1PusherApi - axios parameter creator
 * @export
 */
export const V1PusherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Use this endpoint for pusher client library as authEndpoint in pusher config
         * @summary Authenticate for pusher js library
         * @param {ChatAuthDto} chatAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pusherControllerAuthenticate: async (chatAuthDto: ChatAuthDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatAuthDto' is not null or undefined
            assertParamExists('pusherControllerAuthenticate', 'chatAuthDto', chatAuthDto)
            const localVarPath = `/v1/pusher/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatAuthDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1PusherApi - functional programming interface
 * @export
 */
export const V1PusherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1PusherApiAxiosParamCreator(configuration)
    return {
        /**
         * Use this endpoint for pusher client library as authEndpoint in pusher config
         * @summary Authenticate for pusher js library
         * @param {ChatAuthDto} chatAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pusherControllerAuthenticate(chatAuthDto: ChatAuthDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pusherControllerAuthenticate(chatAuthDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1PusherApi - factory interface
 * @export
 */
export const V1PusherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1PusherApiFp(configuration)
    return {
        /**
         * Use this endpoint for pusher client library as authEndpoint in pusher config
         * @summary Authenticate for pusher js library
         * @param {ChatAuthDto} chatAuthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pusherControllerAuthenticate(chatAuthDto: ChatAuthDto, options?: any): AxiosPromise<object> {
            return localVarFp.pusherControllerAuthenticate(chatAuthDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1PusherApi - object-oriented interface
 * @export
 * @class V1PusherApi
 * @extends {BaseAPI}
 */
export class V1PusherApi extends BaseAPI {
    /**
     * Use this endpoint for pusher client library as authEndpoint in pusher config
     * @summary Authenticate for pusher js library
     * @param {ChatAuthDto} chatAuthDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1PusherApi
     */
    public pusherControllerAuthenticate(chatAuthDto: ChatAuthDto, options?: any) {
        return V1PusherApiFp(this.configuration).pusherControllerAuthenticate(chatAuthDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * V1RatesApi - axios parameter creator
 * @export
 */
export const V1RatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} rateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratesControllerBuyRate: async (rateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rateId' is not null or undefined
            assertParamExists('ratesControllerBuyRate', 'rateId', rateId)
            const localVarPath = `/v1/rates/buy/{rateId}`
                .replace(`{${"rateId"}}`, encodeURIComponent(String(rateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'DISH' | 'INGREDIENT'} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratesControllerGetMyRates: async (filter?: 'DISH' | 'INGREDIENT', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/rates/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'DISH' | 'INGREDIENT'} rateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratesControllerGetRates: async (rateType: 'DISH' | 'INGREDIENT', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'rateType' is not null or undefined
            assertParamExists('ratesControllerGetRates', 'rateType', rateType)
            const localVarPath = `/v1/rates/{rateType}`
                .replace(`{${"rateType"}}`, encodeURIComponent(String(rateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1RatesApi - functional programming interface
 * @export
 */
export const V1RatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1RatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} rateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ratesControllerBuyRate(rateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ratesControllerBuyRate(rateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'DISH' | 'INGREDIENT'} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ratesControllerGetMyRates(filter?: 'DISH' | 'INGREDIENT', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerRateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ratesControllerGetMyRates(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'DISH' | 'INGREDIENT'} rateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ratesControllerGetRates(rateType: 'DISH' | 'INGREDIENT', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ratesControllerGetRates(rateType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * V1RatesApi - factory interface
 * @export
 */
export const V1RatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1RatesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} rateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratesControllerBuyRate(rateId: string, options?: any): AxiosPromise<object> {
            return localVarFp.ratesControllerBuyRate(rateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'DISH' | 'INGREDIENT'} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratesControllerGetMyRates(filter?: 'DISH' | 'INGREDIENT', options?: any): AxiosPromise<CustomerRateResponseDto> {
            return localVarFp.ratesControllerGetMyRates(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'DISH' | 'INGREDIENT'} rateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ratesControllerGetRates(rateType: 'DISH' | 'INGREDIENT', options?: any): AxiosPromise<Array<Rate>> {
            return localVarFp.ratesControllerGetRates(rateType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * V1RatesApi - object-oriented interface
 * @export
 * @class V1RatesApi
 * @extends {BaseAPI}
 */
export class V1RatesApi extends BaseAPI {
    /**
     * 
     * @param {string} rateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1RatesApi
     */
    public ratesControllerBuyRate(rateId: string, options?: any) {
        return V1RatesApiFp(this.configuration).ratesControllerBuyRate(rateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'DISH' | 'INGREDIENT'} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1RatesApi
     */
    public ratesControllerGetMyRates(filter?: 'DISH' | 'INGREDIENT', options?: any) {
        return V1RatesApiFp(this.configuration).ratesControllerGetMyRates(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'DISH' | 'INGREDIENT'} rateType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1RatesApi
     */
    public ratesControllerGetRates(rateType: 'DISH' | 'INGREDIENT', options?: any) {
        return V1RatesApiFp(this.configuration).ratesControllerGetRates(rateType, options).then((request) => request(this.axios, this.basePath));
    }
}


