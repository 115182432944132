import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { ImageInput, LocationSelect } from 'components';
import React, { FC } from 'react';

import { maxLength, promoterInfoMaxLength } from 'utils/validator';

const PromotionEdit: FC<ResourceComponentProps> = (props) => {
  return (
    <Edit title="Изменение рекламного объявления" {...props}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <ImageInput label="Изображение" source="photoUrl" accept="image/*" />
        <BooleanInput label="Статус Активности" source="active" />
        <BooleanInput label="Показывать по всей России" source="aroundRussia" />
        <TextInput label="Заголовок" source="title" />
        <TextInput label="Описание" source="description" multiline validate={maxLength} />
        <TextInput label="Ссылка" source="link" />
        <TextInput
          label={'Информация о рекламодателе'}
          source={'about_promoter'}
          multiline
          validate={promoterInfoMaxLength}
        />
        <DateInput label="дата окончания" source="completionDate" />
        <NumberInput label="Порядок вывода" source="position" step={1} />
        <LocationSelect />
      </SimpleForm>
    </Edit>
  );
};

export default PromotionEdit;
