import { Create, ResourceComponentProps, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from 'react-admin';
import React, { FC } from 'react';

import { LocationSelect } from 'components';

const ManagersCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title={'Создание рекламного объявления'} {...props}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
        redirect={'list'}>
        <SelectInput
          label={'Роль'}
          source={'role'}
          choices={[
            { id: 'admin', name: 'admin' },
            { id: 'super_admin', name: 'super_admin' },
          ]}
        />
        <TextInput label={'Имя'} source={'firstName'} />
        <TextInput label={'Фамилия'} source={'lastName'} />
        <TextInput label={'Электронная Почта'} source={'email'} />
        <TextInput label={'Пароль'} type="password" source={'password'} />
        <TextInput label={'Почта для обратной связи'} source={'feedbackEmail'} />
        <LocationSelect />
      </SimpleForm>
    </Create>
  );
};

export default ManagersCreate;
