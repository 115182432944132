import { JSXElementConstructor, ReactElement, cloneElement } from 'react';

import { Record } from 'react-admin';

const StringToLabelObject = ({ record, children, ...rest }: StringToLabelObjectProps) =>
  cloneElement(children, {
    record: { label: record },
    ...rest,
  });

export default StringToLabelObject;

type StringToLabelObjectProps = { record?: Record; children: ReactElement<any, string | JSXElementConstructor<any>> };
