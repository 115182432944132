import * as React from 'react';

import { MeEdit } from 'routes/Me';
import { Route } from 'react-router-dom';
import Settings from '../Settings';

export default [
  <Route exact path="/me" component={MeEdit} key="me" />,
  <Route exact path="/settings" component={Settings} key="settings" />,
];
