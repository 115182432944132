import {
  DateField,
  ImageField,
  Record,
  ResourceComponentProps,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import React, { FC } from 'react';

import { ResourceName } from 'types';
import styled from 'styled-components';

const SenderShowButton = ({ record }: { record?: Record }) => (
  <ShowButton basePath={`/${ResourceName.CUSTOMERS}`} label="Подробнее" record={{ id: record?.senderId }} />
);

const CustomerShowButton = ({ record }: { record?: Record }) => (
  <ShowButton basePath={`/${ResourceName.CUSTOMERS}`} label="Подробнее" record={{ id: record?.customerId }} />
);

const CustomerReportShow: FC<ResourceComponentProps> = (props) => {
  return (
    <Show title="Жалоба" {...props}>
      <TabbedShowLayout>
        <Tab label="О жалобе">
          <TextField source="id" />
          <TextField sortable={false} label="Имя отправителя" source="customerName" />
          <TextField sortable={false} label="Телефон отправителя" source="customerPhone" />
          <TextField label="Описание" source="description" />
          <TextField label="id отправителя" source="senderId" />
          <TextField label="id получателя" source="customerId" />
          <DateField locales="rus" label="Создано" source="createdAt" />
        </Tab>
        <Tab label="Об отправителе">
          <StyledImageField label="Аватар" source="sender.avatar" />
          <TextField label="id" source="senderId" />
          <TextField label="Имя" source="sender.fullName" />
          <TextField label="Город" source="sender.city" />
          <DateField locales="rus" label="Зарегистрирован" source="sender.createdAt" />
          <SenderShowButton />
        </Tab>
        <Tab label="О получателе">
          <StyledImageField label="Аватар" source="customer.avatar" />
          <TextField label="id" source="customerId" />
          <TextField label="Имя" source="customer.fullName" />
          <TextField label="Город" source="customer.city" />
          <DateField locales="rus" label="Зарегистрирован" source="customer.createdAt" />
          <CustomerShowButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CustomerReportShow;

const StyledImageField = styled(ImageField)`
  img {
    width: 150px;
    height: 150px;
    border-radius: 75px;
  }
`;
