import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  Record,
  ResourceComponentProps,
  ShowButton,
  TextField,
  TextInput,
  useRefresh,
} from 'react-admin';

import BlockIcon from '@material-ui/icons/Block';
import { Customer } from 'api/generated';
import { FC } from 'react';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { api } from 'api';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles({
  button: { color: 'red' },
});

export const CustomersFilter: FC<ResourceComponentProps> = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск по имени" source="fullName||$contL" alwaysOn />
    <TextInput label="Поиск по городу" source="city||$contL" alwaysOn />
    <TextInput label="Поиск по id" source="id||$eq" alwaysOn />
  </Filter>
);

const BanButton = (props: { record?: Record }) => {
  const styles = useStyles();
  const customer = props.record as Customer;
  const refresh = useRefresh();

  const handleBlockCustomer = async () => {
    try {
      if (customer.bannedAt) {
        await api.V1AdminPanelAdminFeaturesApi.adminFeaturesControllerUnbanCustomer(customer.id);
      } else {
        await api.V1AdminPanelAdminFeaturesApi.adminFeaturesControllerBanCustomer(customer.id);
      }
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button
      className={!customer.bannedAt ? styles.button : undefined}
      onClick={handleBlockCustomer}
      label={customer.bannedAt ? 'Разблокировать' : 'Заблокировать'}
      startIcon={customer.bannedAt ? <LockOpenIcon /> : <BlockIcon />}
    />
  );
};

const CustomersList = (props: ResourceComponentProps) => {
  return (
    <List bulkActionButtons={false} filters={<CustomersFilter />} {...props}>
      <Datagrid>
        <StyledImageField sortable={false} label="Аватар" source="avatar" />
        <TextField label="Имя" source="fullName" />
        <TextField label="Телефон" source="phone" />
        <TextField label="Город" source="city" />
        <DateField locales="rus" label="Зарегистрирован" source="createdAt" />
        <DateField locales="rus" label="Заблокирован" source="bannedAt" />
        <ShowButton />
        <BanButton />
      </Datagrid>
    </List>
  );
};

export default CustomersList;

const StyledImageField = styled(ImageField)`
  width: 50px;
  height: 50px;
  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin: 0;
  }
`;
