import { Admin, Login, Resource } from 'react-admin';
import {
  BlockCustomersList,
  BlockCustomersShow,
  CategoriesList,
  CategoryCreate,
  CategoryEdit,
  CategoryShow,
  ChatShow,
  ChatsList,
  CustomerReportShow,
  CustomerReportsList,
  CustomerShow,
  CustomersList,
  ManagersCreate,
  ManagersEdit,
  ManagersList,
  ManagersShow,
  Menu,
  ModeratingOfferShow,
  ModeratingOffersList,
  OfferErrorCreate,
  OfferErrorEdit,
  OfferErrorShow,
  OfferErrorsList,
  OfferShow,
  OffersList,
  PromotionCreate,
  PromotionEdit,
  PromotionShow,
  PromotionsList,
} from 'routes';
import { authProvider, dataProvider } from 'providers';

import BlockIcon from '@material-ui/icons/Block';
import CategoryIcon from '@material-ui/icons/Category';
import ChatIcon from '@material-ui/icons/Chat';
import { CustomRoutes } from './routes/CustomRoutes';
import GroupIcon from '@material-ui/icons/Group';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ModeratingOffersIcon from '@material-ui/icons/Loop';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import OfferErrorIcon from '@material-ui/icons/Help';
import ReportIcon from '@material-ui/icons/Report';
import { ResourceName } from 'types';
import polyglotI18nProvider from 'ra-i18n-polyglot';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import russianMessages from 'ra-language-russian';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const App = () => {
  return (
    <Admin
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      menu={Menu}
      authProvider={authProvider}
      loginPage={Login}
      customRoutes={CustomRoutes}
    >
      {(permissions) => {
        return [
          <Resource
            key={ResourceName.PROMOTIONS}
            name={ResourceName.PROMOTIONS}
            options={{ label: 'Рекламные объявления' }}
            list={PromotionsList}
            show={PromotionShow}
            create={PromotionCreate}
            edit={PromotionEdit}
            icon={MonetizationOnIcon}
          />,
          <Resource
            key={ResourceName.CATEGORIES}
            name={ResourceName.CATEGORIES}
            options={{ label: 'Категории' }}
            list={CategoriesList}
            show={CategoryShow}
            create={CategoryCreate}
            edit={CategoryEdit}
            icon={CategoryIcon}
          />,
          <Resource
            key={ResourceName.OFFERS}
            name={ResourceName.OFFERS}
            options={{ label: 'Объявления' }}
            list={OffersList}
            show={OfferShow}
            icon={LocalOfferIcon}
          />,
          <Resource
            key={ResourceName.MODERATING_OFFERS}
            name={ResourceName.MODERATING_OFFERS}
            options={{ label: 'На модерации' }}
            list={ModeratingOffersList}
            show={ModeratingOfferShow}
            icon={ModeratingOffersIcon}
          />,
          <Resource
            key={ResourceName.CUSTOMERS}
            name={ResourceName.CUSTOMERS}
            options={{ label: 'Пользователи' }}
            list={CustomersList}
            show={CustomerShow}
            icon={GroupIcon}
          />,
          permissions === 'super_admin' ? (
            <Resource
              key={ResourceName.MANAGERS}
              name={ResourceName.MANAGERS}
              options={{ label: 'Администраторы' }}
              show={ManagersShow}
              list={ManagersList}
              create={ManagersCreate}
              edit={ManagersEdit}
              icon={GroupIcon}
            />
          ) : (
            <Resource
              key={ResourceName.ME}
              name={ResourceName.ME}
              options={{ label: 'Профиль' }}
              edit={ManagersEdit}
              icon={GroupIcon}
            />
          ),
          <Resource
            key={ResourceName.CUSTOMER_REPORTS}
            name={ResourceName.CUSTOMER_REPORTS}
            options={{ label: 'Жалобы' }}
            list={CustomerReportsList}
            show={CustomerReportShow}
            icon={ReportIcon}
          />,
          <Resource
            key={ResourceName.BLOCK_CUSTOMERS}
            name={ResourceName.BLOCK_CUSTOMERS}
            options={{ label: 'Блокировки' }}
            list={BlockCustomersList}
            show={BlockCustomersShow}
            icon={BlockIcon}
          />,
          permissions === 'super_admin' && (
            <Resource
              key={ResourceName.CHATS}
              name={ResourceName.CHATS}
              options={{ label: 'Диалоги' }}
              list={ChatsList}
              show={ChatShow}
              icon={ChatIcon}
            />
          ),
          permissions === 'super_admin' && (
            <Resource key={ResourceName.MESSAGES} name={ResourceName.MESSAGES} options={{ label: 'Сообщения' }} />
          ),
          <Resource
            key={ResourceName.OFFER_ERRORS}
            name={ResourceName.OFFER_ERRORS}
            options={{ label: 'Причины отклонения' }}
            list={OfferErrorsList}
            show={OfferErrorShow}
            create={OfferErrorCreate}
            edit={OfferErrorEdit}
            icon={OfferErrorIcon}
          />,
        ];
      }}
    </Admin>
  );
};

export default App;
