import { AppConfig } from 'config';
import Axios from 'axios';

const http = Axios.create({
  baseURL: AppConfig.BASE_API_URL,
  responseType: 'json',
  timeout: 10000,
});

http.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');

  if (config.headers) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
});

export default http;
