import {
  Datagrid,
  DateField,
  Filter,
  List,
  ResourceComponentProps,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';

import { FC } from 'react';

export const CustomerReportsFilter: FC<ResourceComponentProps> = (props) => (
  <Filter {...props}>
    <TextInput label="По id отправителя" source="senderId||$eq" alwaysOn />
    <TextInput label="По id получателя" source="customerId||$eq" alwaysOn />
  </Filter>
);

const CustomerReportsList = (props: ResourceComponentProps) => {
  return (
    <List filters={<CustomerReportsFilter />} {...props}>
      <Datagrid>
        <TextField sortable={false} label="Имя отправителя" source="customerName" />
        <TextField sortable={false} label="Телефон отправителя" source="customerPhone" />
        <TextField sortable={false} label="Имя получателя" source="customer.fullName" />
        <DateField locales="rus" label="Создано" source="createdAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default CustomerReportsList;
