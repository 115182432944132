import {
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  ResourceComponentProps,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';
import React, { FC } from 'react';

import { OfferStatusEnum } from 'api/generated';
import styled from 'styled-components';

export const ModeratingOffersFilter: FC<ResourceComponentProps> = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск по названию" source="title||$contL" alwaysOn />
    <TextInput label="Поиск по городу" source="city||$contL" alwaysOn />
  </Filter>
);

const ModeratingOffersList = (props: ResourceComponentProps) => {
  return (
    <List
      filters={<ModeratingOffersFilter />}
      filterDefaultValues={{ ['status||$eq']: OfferStatusEnum.OnModeration }}
      {...props}
    >
      <Datagrid>
        <StyledImageField sortable={false} label="Изображение" source="photoUrls.[0]" />
        <TextField label="Заголовок" source="title" />
        <TextField label="Город" source="city" />
        <TextField label="Автор" source="customer.fullName" />
        <TextField label="Тип" source="type" />
        <TextField label="Категория" source="offerWithCategories.[0].category.title" />
        <DateField locales="rus" label="Создано" source="createdAt" />
        <DateField locales="rus" label="Обновлено" source="updatedAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default ModeratingOffersList;

const StyledImageField = styled(ImageField)`
  width: 50px;
  height: 50px;
  img {
    width: 50px;
    height: 50px;
    margin: 0;
  }
`;
