export const isRequired = (value: string): string | undefined => {
  return value ? undefined : 'Required';
};

export const passwordLength = (value: string): string | undefined => {
  if (value === '' || value === undefined) {
    return undefined;
  }

  return value?.length >= 8 ? undefined : 'Пароль должен быть не менее 8 символов';
};

export const maxLength = (value: string): string | undefined => {
  return value?.length > 50 ? `Описание должен быть не более 50 символов. Сейчас ${value.length}` : undefined;
};

export const promoterInfoMaxLength = (value: string): string | undefined => {
  return value?.length > 150 ? `Описание должен быть не более 150 символов. Сейчас ${value.length}` : undefined;
};

export const composeValidators =
  (...validators: any[]) =>
  (value: string) =>
    validators.reduce((error, validator) => error || validator(value), undefined);
