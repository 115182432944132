import { AutocompleteInput, Edit, ResourceComponentProps, SimpleForm, TextInput } from 'react-admin';
import React, { FC } from 'react';

const OfferErrorEdit: FC<ResourceComponentProps> = (props) => (
  <Edit title="Изменение причины отклонения" {...props}>
    <SimpleForm>
      <TextInput label="Название" source="title" />
      <TextInput label="Сообщение" source="message" />
    </SimpleForm>
  </Edit>
);

export default OfferErrorEdit;
