import { DateField, ResourceComponentProps, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import React, { FC } from 'react';

const CategoryShow: FC<ResourceComponentProps> = (props) => {
  return (
    <Show title="Категория" {...props}>
      <TabbedShowLayout>
        <Tab label="О категории">
          <TextField source="id" />
          <TextField label="Название" source="title" />
          <TextField label="Тип" source="type" />
          <DateField locales="rus" label="Создано" source="createdAt" />
          <DateField locales="rus" label="Обновлено" source="updatedAt" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default CategoryShow;
