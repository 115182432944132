import {
  BooleanInput,
  Create,
  DateInput,
  NumberInput,
  ResourceComponentProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { ImageInput, LocationSelect } from 'components';

import { FC } from 'react';
import { maxLength, promoterInfoMaxLength } from 'utils/validator';

export const PromotionCreate: FC<ResourceComponentProps> = (props) => {
  const transform = (data: any) => {
    const newData = {
      ...data,
      active: data?.active ? data.active : false,
      aroundRussia: data?.aroundRussia ? data.aroundRussia : false,
    };
    return newData;
  };

  return (
    <Create title={'Создание рекламного объявления'} {...props} transform={transform}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
        redirect={'list'}
      >
        <ImageInput label={'Изображение'} source={'photoUrl'} accept={'image/*'} />
        <BooleanInput label="Статус Активности" source="active" />
        <BooleanInput label="Показывать по всей России" source="aroundRussia" />
        <TextInput label={'Заголовок'} source={'title'} />
        <TextInput label={'Описание'} source={'description'} multiline validate={maxLength} />
        <TextInput label={'Ссылка'} source={'link'} />
        <TextInput
          label={'Информация о рекламодателе'}
          source={'about_promoter'}
          multiline
          validate={promoterInfoMaxLength}
        />
        <DateInput label="дата окончания" source="completionDate" />
        <NumberInput label="Порядок вывода" source="position" step={1} />
        <LocationSelect />
      </SimpleForm>
    </Create>
  );
};

export default PromotionCreate;
