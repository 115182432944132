import {
  Datagrid,
  DateField,
  Filter,
  List,
  ResourceComponentProps,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';

import { FC } from 'react';

export const BlockCustomersFilter: FC<ResourceComponentProps> = (props) => (
  <Filter {...props}>
    <TextInput label="По id пользователя" source="customerId||$eq" alwaysOn />
    <TextInput label="По id забаненного" source="blockedCustomerId||$eq" alwaysOn />
  </Filter>
);

const BlockCustomersList = (props: ResourceComponentProps) => {
  return (
    <List filters={<BlockCustomersFilter />} {...props}>
      <Datagrid>
        <TextField sortable={false} label="Имя пользователя" source="customer.fullName" />
        <TextField sortable={false} label="Имя забаненного пользователя" source="blockedCustomer.fullName" />
        <DateField locales="rus" label="Создано" source="createdAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default BlockCustomersList;
