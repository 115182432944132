import {
  ArrayField,
  Button,
  DateField,
  ImageField,
  Show,
  ShowProps,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  useRedirect,
} from 'react-admin';
import React, { FC } from 'react';

import OfferRejectTab from './OfferRejectTab';
import { OfferStatusEnum } from 'api/generated';
import { StringToLabelObject } from 'components';
import { http } from 'services';

const ModeratingOfferShow: FC<ShowProps> = (props) => {
  const redirect = useRedirect();

  const approve = async () => {
    const body = {
      errors: [],
      status: OfferStatusEnum.Opened,
    };
    await http.patch(`/v1/admin/offers/process/${props.id}`, body);
    redirect('/moderating-offers');
  };

  return (
    <Show title="Объявление на модерации" {...props}>
      <TabbedShowLayout>
        <Tab label="Информация">
          <TextField source="id" />
          <ArrayField label="Изображения" source="photoUrls">
            <SingleFieldList>
              <StringToLabelObject>
                <ImageField source="label" />
              </StringToLabelObject>
            </SingleFieldList>
          </ArrayField>
          <TextField label="Заголовок" source="title" />
          <TextField label="Описание" source="description" />
          <TextField label="Город" source="city" />
          <TextField label="Тип" source="type" />
          <TextField label="Категория" source="offerWithCategories.[0].category.title" />
          <TextField label="Статус" source="status" />
          <DateField locales="rus" label="Создано" source="createdAt" />
          <DateField locales="rus" label="Обновлено" source="updatedAt" />
          <Button color="primary" onClick={approve}>
            <p>Подтвердить</p>
          </Button>
        </Tab>
        <Tab label="Отклонить">
          <OfferRejectTab offerId={props.id} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ModeratingOfferShow;
