import {
  Datagrid,
  DateField,
  ImageField,
  Pagination,
  Record,
  ReferenceManyField,
  ResourceComponentProps,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import React, { FC } from 'react';

import { ResourceName } from 'types';
import styled from 'styled-components';

const OfferShowButton = ({ record }: { record?: Record }) => (
  <ShowButton basePath={`/${ResourceName.OFFERS}`} label="Объявление" record={{ id: record?.offerId }} />
);

const CustomerTheFirstShowButton = ({ record }: { record?: Record }) => (
  <ShowButton
    basePath={`/${ResourceName.CUSTOMERS}`}
    label="Подробнее"
    record={{ id: record?.chatMembers[0].customerId }}
  />
);

const CustomerTheSecondShowButton = ({ record }: { record?: Record }) => (
  <ShowButton
    basePath={`/${ResourceName.CUSTOMERS}`}
    label="Подробнее"
    record={{ id: record?.chatMembers[1].customerId }}
  />
);

const ChatShow: FC<ResourceComponentProps> = (props) => {
  return (
    <Show title="Диалог" {...props}>
      <TabbedShowLayout>
        <Tab label="Диалог">
          <TextField source="id" />
          <TextField label="Объявление" source="title" />
          <TextField label="id объявления" source="offerId" />
          <TextField label="id пользователя 1" source="chatMembers[0].customerId" />
          <TextField label="id пользователя 2" source="chatMembers[1].customerId" />
          <DateField locales="rus" label="Создано" source="createdAt" />
          <OfferShowButton />
        </Tab>
        <Tab label="Сообщения">
          <ReferenceManyField
            sort={{ field: 'createdAt', order: 'DESC' }}
            label=""
            pagination={<Pagination />}
            reference={ResourceName.MESSAGES}
            target="chatId"
          >
            <Datagrid>
              <TextField label="Сообщение" source="message" />
              <TextField label="Автор" source="senderName" />
              <DateField locales="rus" label="Отправлено" source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Пользователь 1">
          <StyledImageField label="Аватар" source="chatMembers[0].customer.avatar" />
          <TextField label="id" source="chatMembers[0].customerId" />
          <TextField label="Имя" source="chatMembers[0].customer.fullName" />
          <TextField label="Город" source="chatMembers[0].customer.city" />
          <DateField locales="rus" label="Зарегистрирован" source="chatMembers[0].customer.createdAt" />
          <CustomerTheFirstShowButton />
        </Tab>
        <Tab label="Пользователь 2">
          <StyledImageField label="Аватар" source="chatMembers[1].customer.avatar" />
          <TextField label="id" source="chatMembers[1].customerId" />
          <TextField label="Имя" source="chatMembers[1].customer.fullName" />
          <TextField label="Город" source="chatMembers[1].customer.city" />
          <DateField locales="rus" label="Зарегистрирован" source="chatMembers[1].customer.createdAt" />
          <CustomerTheSecondShowButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ChatShow;

const StyledImageField = styled(ImageField)`
  img {
    width: 150px;
    height: 150px;
    border-radius: 75px;
  }
`;
