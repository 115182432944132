import { Edit, ResourceComponentProps, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from 'react-admin';
import React, { FC } from 'react';

import { LocationSelect } from 'components';
import { passwordLength } from 'utils/validator';

const ManagersEdit: FC<ResourceComponentProps> = (props) => {
  return (
    <Edit title="Изменение данных менеджера" {...props}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
        redirect={'list'}>
        <SelectInput
          label={'Роль'}
          source={'role'}
          choices={[
            { id: 'admin', name: 'admin' },
            { id: 'super_admin', name: 'super_admin' },
          ]}
        />
        <TextInput label={'Имя'} source={'firstName'} />
        <TextInput label={'Фамилия'} source={'lastName'} />
        <TextInput label={'Электронная Почта'} source={'email'} />
        <TextInput label={'Новый Пароль'} type="password" source={'password'} validate={passwordLength} />
        <TextInput label={'Почта для обратной связи'} source={'feedbackEmail'} />
        <LocationSelect />
      </SimpleForm>
    </Edit>
  );
};

export default ManagersEdit;
