import axios, { AxiosRequestConfig } from 'axios';

import { http } from './';

export interface FilePutPayload {
  url: string;
  file: File;
  options?: AxiosRequestConfig;
}

class AWS {
  putFile = async ({ url, file, options }: FilePutPayload) => {
    try {
      return await http.put(url, file, {
        ...options,
        headers: {
          'Content-Type': file.type,
        },
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        return { status: 'cancelled' };
      }

      throw typeof error === 'string' ? new Error(error) : error;
    }
  };
}

export default new AWS();
