import {
  Datagrid,
  DateField,
  Filter,
  List,
  ResourceComponentProps,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';
import React, { FC } from 'react';

export const CategoriesFilter: FC<ResourceComponentProps> = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск по названию" source="title||$contL" alwaysOn />
  </Filter>
);

const CategoriesList = (props: ResourceComponentProps) => {
  return (
    <List filters={<CategoriesFilter />} {...props}>
      <Datagrid>
        <TextField label="Название" source="title" />
        <TextField label="Тип" source="type" />
        <DateField locales="rus" label="Создано" source="createdAt" />
        <DateField locales="rus" label="Изменено" source="updatedAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default CategoriesList;
