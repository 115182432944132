export enum ResourceName {
  CATEGORIES = 'categories',
  OFFERS = 'offers',
  MODERATING_OFFERS = 'moderating-offers',
  CUSTOMERS = 'customers',
  CUSTOMER_REPORTS = 'customer-reports',
  BLOCK_CUSTOMERS = 'block-customers',
  CHATS = 'chats',
  MESSAGES = 'messages',
  PROMOTIONS = 'promotions',
  MANAGERS = 'managers',
  ME = 'me',
  UPDATE_MY_PROFILE = 'update-my-profile',
  OFFER_ERRORS = 'offer-errors',
}
