import React, { FC, useEffect } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';

import { OfferError } from 'types';
import { useForm } from 'react-final-form';

const ErrorSelectInput: FC<Omit<SelectInputProps, 'onSelect'>> = ({ onSelect, ...props }) => {
  const form = useForm();

  const inputValue = props.input.value;
  const choices = props.choices;

  useEffect(() => {
    if (choices.length && inputValue) {
      const offerError = choices.find((error: OfferError) => error.id === inputValue);
      const parsedError = { id: offerError.id, title: offerError.title, message: offerError.message };
      onSelect(parsedError);

      form.change(props.input.name, '');
    }
  }, [inputValue]);

  return <SelectInput {...props} />;
};

export default ErrorSelectInput;
