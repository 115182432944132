import { Alert, Snackbar } from '@mui/material';
import { Field, Form } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import { SaveButton, Title, useRefresh } from 'react-admin';
import { isRequired, passwordLength } from 'utils/validator';

import { Admin } from 'types';
import { AppConfig } from 'config';
import Card from '@material-ui/core/Card';
import { TextField } from '@mui/material';
import { api } from 'api';
import styled from 'styled-components';

const MeEdit = ({ staticContext, ...props }: { staticContext: any }) => {
  const [admin, setAdmin] = useState<Admin>({});
  const [open, setOpen] = useState(false);
  const refresh = useRefresh();

  const getAdmin = async () => {
    const { data } = await api.V1AdminsApi.adminsControllerGetMe();
    setAdmin(data);
  };

  useEffect(() => {
    getAdmin();
  }, []);

  const snackVisible = () => {
    setOpen(true);
    setTimeout(() => setOpen(false), 3000);
  };

  const onSubmit = async (values: any) => {
    try {
      const data = await fetch(`${AppConfig.BASE_API_URL}/v1/admin/me`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(values),
      });
      const response = await data.json();

      if (response?.success) snackVisible();

      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const CustomInput = ({ input, meta, placeholder, disabled = false, hide = false }: CustomInput) => {
    const { invalid, touched } = meta;
    const hasLocalError = !!invalid && !!touched;

    return (
      <Root style={{ display: hide ? 'none' : 'block' }}>
        <Input
          style={{
            border: hasLocalError ? '2px solid red' : '2px solid transparent',
          }}
          variant="filled"
          disabled={disabled}
          placeholder={placeholder}
          {...input}
        />
      </Root>
    );
  };

  return (
    <Card>
      <Title title="Настройки профиля" />
      <Form onSubmit={onSubmit}>
        {(props) => {
          return (
            <CustomForm noValidate onSubmit={props.handleSubmit}>
              <Field name={'firstName'} validate={isRequired} defaultValue={admin.firstName}>
                {(prop) => <CustomInput placeholder="Имя" {...prop} />}
              </Field>
              <Field name={'lastName'} validate={isRequired} defaultValue={admin.lastName}>
                {(prop) => <CustomInput placeholder="Фамилия" {...prop} />}
              </Field>
              <Field name={'email'} validate={isRequired} defaultValue={admin.email}>
                {(prop) => <CustomInput placeholder="Email" {...prop} />}
              </Field>
              <Field name={'feedbackEmail'} validate={isRequired} defaultValue={admin.feedbackEmail}>
                {(prop) => <CustomInput placeholder="Email для рекламы" {...prop} />}
              </Field>
              <Field name={'password'} validate={passwordLength} initialValue={''}>
                {(prop) => <CustomInput placeholder="Новый пароль" {...prop} />}
              </Field>
              <Field name={'city'} initialValue={admin.city} validate={isRequired}>
                {(prop) => <CustomInput {...prop} disabled={true} placeholder="Город" />}
              </Field>
              <Field name={'region'} initialValue={admin.region}>
                {(prop) => <CustomInput {...prop} disabled={true} placeholder="Регион" />}
              </Field>
              <SaveButton
                type="submit"
                handleSubmitWithRedirect={props.handleSubmit}
                style={{ display: 'block', marginTop: 20 }}
              />
              <Snackbar
                style={{ position: 'absolute', bottom: 20, right: 20, left: 'auto' }}
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}>
                <Alert severity="success">Изменения сохранены</Alert>
              </Snackbar>
            </CustomForm>
          );
        }}
      </Form>
    </Card>
  );
};

export default MeEdit;

type CustomInput = {
  input: any;
  meta: any;
  hide?: boolean;
  disabled?: boolean;
  placeholder?: string;
};

const CustomForm = styled.form`
  position: relative;
  padding: 20px;
`;

const Root = styled.div`
  margin-bottom: 20px;
`;
const Input = styled(TextField)`
  position: relative;
  border-radius: 4px 4px 0 0;
  align-items: center;
  width: 256px;

  & .MuiInputBase-root {
    width: 100%;
  }
`;
